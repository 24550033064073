import React, { ChangeEvent, useEffect, useState } from 'react';
import nexo from '../../nexoClient';
import {
  Page,
  Layout,
  FormField,
  EmptyMessage,
  InteractiveList,
  DataList,
} from '@nimbus-ds/patterns';
import { navigateHeader } from '@tiendanube/nexo';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Icon,
  IconButton,
  Input,
  Link,
  Select,
  Sidebar,
  Spinner,
  Tag,
  Text,
  Thumbnail,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import {
  defaultStoreConfig,
  defaultStoreConfigBlockDate,
  IStoreConfig,
  IStoreConfigBlockDate,
  IStoreConfigBlockDatePeriods,
} from '../../lib/interfaces/store-config.interface';
import apiInstance from '../../utils/apiUtils';
import {
  CheckCircleIcon,
  ForbiddenIcon,
  PlusCircleIcon,
  StarIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { IShippingConfigPeriod } from '../../lib/interfaces/shipping-config.interface';
import { formatDate } from '../../utils/formatUtils';
import { MaxLetsForm } from '../../components/IntegrationsConfigs';

type TIntegrations = 'MaxLets' | null;

const ConfigsPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [openSidebarIntegration, setOpenSidebarIntegration] =
    useState<TIntegrations>();
  const [loadingPeriods, setLoadingPeriods] = useState<boolean>(false);
  const [formData, setFormData] = useState<IStoreConfig>({
    ...defaultStoreConfig,
  });
  const [blockDateSideBarOpen, setBlockDateSideBarOpen] =
    useState<boolean>(false);
  const [periods, setPeriods] = useState<IShippingConfigPeriod[]>([]);
  const [blockDateForm, setBlockDateForm] = useState<IStoreConfigBlockDate>(
    defaultStoreConfigBlockDate,
  );

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/store-config`);
      if (result.data !== null) {
        const resData = result.data;
        setFormData({ ...defaultStoreConfig, ...resData });
      }
    } catch (error) {
      setFormData({ ...defaultStoreConfig });
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ConfigsPage.Toasts.Error.GetConfig')}`,
        position: 4,
      });
    }
    setLoading(false);
  };

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      // Verifica se o campo é aninhado
      const keys = name.split('.');
      const updatedState: any = { ...prevState }; // Alterado para const

      if (keys.length > 1) {
        let nestedState = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!nestedState[keys[i]]) nestedState[keys[i]] = {};
          nestedState = nestedState[keys[i]];
        }
        nestedState[keys[keys.length - 1]] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      } else {
        updatedState[name] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      }

      return updatedState;
    });
  };

  const handleSubmit = async () => {
    if (!formData) return;

    try {
      setLoading(true);
      const payload = { ...formData };

      // Remova os campos que não precisam ser enviados
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;

      const result = await apiInstance.post(`/store-config`, payload);

      if (result.status === 200 && result.data.status) {
        addToast({
          id: `submit_${new Date().toISOString()}`,
          type: 'success',
          text: `${t('ConfigsPage.Toasts.Success.Save')}`,
          position: 4,
        });
      } else {
        throw new Error('Erro ao salvar configurações');
      }
    } catch (error) {
      addToast({
        id: `submit_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('ConfigsPage.Toasts.Error.Save')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const openBlockDatesSidebar = () => {
    setBlockDateSideBarOpen(true);
    getPeriods();
  };

  const getPeriods = async () => {
    setLoadingPeriods(true);
    try {
      const result = await apiInstance.get(`/shipping-config/periods`);
      const blockPeriods: IStoreConfigBlockDatePeriods = {};
      if (result.data !== null) {
        const periods = result.data as IShippingConfigPeriod[];
        for (let x = 0; x < periods.length; x++) {
          const period = periods[x];
          if (period._id) {
            blockPeriods[period._id] = {
              block: true,
              period_id: period._id,
              period_name: period.name,
            };
          }
        }
      }
      setBlockDateForm({
        ...defaultStoreConfigBlockDate,
        periods: blockPeriods,
      });
    } catch (error) {
      setPeriods([]);
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `Nenhum periodo encontrado`,
        position: 4,
      });
    }
    setLoadingPeriods(false);
  };

  const handleChangeBlockDateForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;

    setBlockDateForm((prevState) => {
      // Verifica se o campo é aninhado
      const keys = name.split('.');
      const updatedState: any = { ...prevState }; // Alterado para const

      if (keys.length > 1) {
        let nestedState = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!nestedState[keys[i]]) nestedState[keys[i]] = {};
          nestedState = nestedState[keys[i]];
        }
        nestedState[keys[keys.length - 1]] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      } else {
        updatedState[name] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      }

      return updatedState;
    });
  };

  const handleAddPeriodToList = (checked: boolean, key: string) => {
    blockDateForm.periods[key].block = checked;
    setBlockDateForm({ ...blockDateForm });
  };

  const addBlockDate = () => {
    formData.blockDates.push(blockDateForm);
    setFormData({ ...formData });
    setBlockDateSideBarOpen(false);
    setBlockDateForm({ ...defaultStoreConfigBlockDate });

    addToast({
      id: new Date().toISOString(),
      type: 'success',
      text: `${t('ConfigsPage.Toasts.Success.NewDate')}`,
      position: 4,
    });
  };

  const handleRemoveBlockDate = (indexToRemove: number) => {
    if (indexToRemove > -1 && indexToRemove < formData.blockDates.length) {
      formData.blockDates.splice(indexToRemove, 1);
    }
    setFormData({ ...formData });
    addToast({
      id: new Date().toISOString(),
      type: 'success',
      text: `${t('ConfigsPage.Toasts.Success.RemoveDate')}`,
      position: 4,
    });
  };

  const toggleOpenSidebarIntegration = (type: TIntegrations) => {
    setOpenSidebarIntegration(type);
  };

  return (
    <>
      <Page>
        <Page.Header title={t('ConfigsPage.Title')} />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="6" boxSizing="border-box">
                {loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <Spinner size="large" />
                  </Box>
                ) : (
                  <>
                    {/* Formulário de Agendamento de Entrega */}
                    <Card>
                      <Card.Header>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Title as="h4">{`${t(
                            'ConfigsPage.Cards.DeliveryScheduling.Title',
                          )}`}</Title>
                          <Toggle
                            name="deliveryScheduling.active"
                            label={`${t(
                              'ConfigsPage.Cards.DeliveryScheduling.Inputs.Active.Label',
                            )}`}
                            disabled={loading}
                            active={formData.deliveryScheduling?.active}
                            onChange={handleChangeForm}
                          />
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.Cards.DeliveryScheduling.Inputs.calendaryTitle.Label',
                            )}`}
                          >
                            <Input
                              disabled={loading}
                              id="deliveryScheduling.calendaryTitle"
                              name="deliveryScheduling.calendaryTitle"
                              onChange={handleChangeForm}
                              value={
                                formData.deliveryScheduling?.calendaryTitle ||
                                ''
                              }
                            />
                          </FormField>
                        </Box>
                      </Card.Body>
                    </Card>

                    {/* Formulário de Configurações de Impressora */}
                    <Card>
                      <Card.Header>
                        <Title as="h4">{`${t(
                          'ConfigsPage.Cards.Print.Title',
                        )}`}</Title>
                      </Card.Header>
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ConfigsPage.Cards.Print.Inputs.PaperWidth.Label',
                            )}`}
                          >
                            <Select
                              id="printerConfig.paperWidth"
                              name="printerConfig.paperWidth"
                              value={formData.printerConfig.paperWidth}
                              onChange={handleChangeForm}
                            >
                              <Select.Option value="80mm" label="80mm" />
                              <Select.Option value="58mm" label="58mm" />
                            </Select>
                          </FormField>
                          <FormField
                            label={`${t(
                              'ConfigsPage.Cards.Print.Inputs.FontSize.Label',
                            )}`}
                          >
                            <Input
                              type="number"
                              name="printerConfig.fontSize"
                              value={formData.printerConfig.fontSize}
                              onChange={handleChangeForm}
                              placeholder="Ex.: 12"
                            />
                          </FormField>
                          <FormField
                            label={`${t(
                              'ConfigsPage.Cards.Print.Inputs.EnableQRCode.Label',
                            )}`}
                          >
                            <Toggle
                              name="printerConfig.enableQRCode"
                              label="Ativar"
                              active={formData.printerConfig.enableQRCode}
                              onChange={handleChangeForm}
                            />
                          </FormField>
                        </Box>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header>
                        <Box display="flex" justifyContent="space-between">
                          <Title as="h4">{`${t(
                            'ConfigsPage.Cards.CloseDates.Title',
                          )}`}</Title>
                          <Link
                            as="button"
                            appearance="primary"
                            onClick={openBlockDatesSidebar}
                          >
                            <Icon
                              color="currentColor"
                              source={<PlusCircleIcon />}
                            />
                            {`${t('ConfigsPage.Cards.CloseDates.CtaNew')}`}
                          </Link>
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        <Box display="grid" gap="6">
                          {formData.blockDates.length ? (
                            <DataList>
                              {formData.blockDates.map((item, i) => (
                                <DataList.Row key={i} gap="1">
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    <Box display="grid" gap="3">
                                      <Box display="flex" gap="3">
                                        <Text
                                          fontSize="caption"
                                          fontWeight="medium"
                                        >
                                          {`${t('ConfigsPage.Cards.CloseDates.List.BlockDateText')}`}
                                          {` `}
                                          <Text
                                            fontSize="highlight"
                                            fontWeight="bold"
                                            as="span"
                                          >
                                            {formatDate(
                                              item.date,
                                              'long',
                                              true,
                                            )}
                                          </Text>
                                        </Text>

                                        {item.is_interior === true ? (
                                          <Tag appearance="primary">
                                            <StarIcon size={12} />{' '}
                                            {`${t('ConfigsPage.Cards.CloseDates.List.Interior')}`}
                                          </Tag>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                      <Box
                                        display="flex"
                                        gap="2"
                                        flexWrap="wrap"
                                      >
                                        {Object.entries(item.periods).map(
                                          ([key, period]: [string, any]) => (
                                            <Tag
                                              appearance={
                                                period.block
                                                  ? 'danger'
                                                  : 'neutral'
                                              }
                                              key={key}
                                            >
                                              {period.block ? (
                                                <ForbiddenIcon size={12} />
                                              ) : (
                                                <CheckCircleIcon size={12} />
                                              )}
                                              <Text color="neutral-textLow">
                                                {period.period_name}
                                              </Text>
                                            </Tag>
                                          ),
                                        )}
                                      </Box>
                                    </Box>
                                    <IconButton
                                      onClick={() => handleRemoveBlockDate(i)}
                                      source={<TrashIcon />}
                                    />
                                  </Box>
                                </DataList.Row>
                              ))}
                            </DataList>
                          ) : (
                            <EmptyMessage
                              title={`${t('ConfigsPage.Cards.CloseDates.EmptyMessage.Title')}`}
                              text={`${t('ConfigsPage.Cards.CloseDates.EmptyMessage.Text')}`}
                              icon={<ForbiddenIcon size={32} />}
                              actions={
                                <Link
                                  as="button"
                                  appearance="primary"
                                  onClick={openBlockDatesSidebar}
                                >
                                  <Icon
                                    color="currentColor"
                                    source={<PlusCircleIcon />}
                                  />
                                  {`${t('ConfigsPage.Cards.CloseDates.CtaNew')}`}
                                </Link>
                              }
                            />
                          )}
                        </Box>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header
                        title={`${t(
                          'ConfigsPage.Cards.DeliveryIntegrations.Title',
                        )}`}
                      />
                      <Card.Body>
                        <Box display="grid" flexDirection="column" gap="6">
                          <Thumbnail
                            src="/lets_delivery_logo.jpeg"
                            alt="my-image"
                            width="104px"
                            onClick={() => setOpenSidebarIntegration('MaxLets')}
                          />
                        </Box>
                      </Card.Body>
                    </Card>

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        disabled={loading}
                        appearance="primary"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <Spinner color="currentColor" size="small" />
                        ) : (
                          ''
                        )}
                        {t('General.save')}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>

      <Sidebar
        padding="none"
        open={blockDateSideBarOpen}
        onRemove={() => setBlockDateSideBarOpen(false)}
      >
        <Sidebar.Header
          padding="base"
          title={`${t('ConfigsPage.Sidebar.CloseDates.Title')}`}
        />
        <Sidebar.Body>
          <Box display="grid" gap="6">
            <Box paddingX="4" display="grid" gap="6">
              <FormField
                label={`${t('ConfigsPage.Sidebar.CloseDates.Input.DateLabel')}`}
              >
                <Input
                  type="date"
                  name="date"
                  value={blockDateForm.date}
                  onChange={handleChangeBlockDateForm}
                />
              </FormField>
              <Checkbox
                name="is_interior"
                label={`${t('ConfigsPage.Sidebar.CloseDates.Input.IsInterior')}`}
                checked={blockDateForm.is_interior}
                onChange={handleChangeBlockDateForm}
              />
            </Box>

            <Text textAlign="center" fontWeight="medium">
              {`${t('ConfigsPage.Sidebar.CloseDates.TextPeriods')}`}
            </Text>
            {blockDateForm || loadingPeriods ? (
              <InteractiveList>
                {loadingPeriods ? (
                  <>
                    <InteractiveList.ToggleItemSkeleton
                      title=""
                      toggle={{
                        name: 'toggle-element',
                      }}
                    />
                    <InteractiveList.ToggleItemSkeleton
                      title=""
                      toggle={{
                        name: 'toggle-element',
                      }}
                    />
                    <InteractiveList.ToggleItemSkeleton
                      title=""
                      toggle={{
                        name: 'toggle-element',
                      }}
                    />
                  </>
                ) : (
                  Object.entries(blockDateForm.periods).map(
                    ([key, period]: [string, any]) => (
                      <InteractiveList.ToggleItem
                        key={period.period_id}
                        title={period.period_name}
                        toggle={{
                          name: 'toggle-element-' + key,
                          checked: period.block,
                          onChange: (e) =>
                            handleAddPeriodToList(e.target.checked, key),
                        }}
                      ></InteractiveList.ToggleItem>
                    ),
                  )
                )}
              </InteractiveList>
            ) : (
              <Box padding="4">
                <EmptyMessage
                  title={`${t('ConfigsPage.Sidebar.CloseDates.EmptyMessage.Title')}`}
                  text={`${t('ConfigsPage.Sidebar.CloseDates.EmptyMessage.Text')}`}
                />
              </Box>
            )}
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer padding="base">
          <Box
            alignItems="center"
            boxSizing="border-box"
            display="flex"
            height="100%"
            justifyContent="center"
            padding="2"
            width="100%"
          >
            <Button
              disabled={loading}
              appearance="primary"
              type="submit"
              onClick={addBlockDate}
            >
              {loading ? <Spinner color="currentColor" size="small" /> : ''}
              {t('General.save')}
            </Button>
          </Box>
        </Sidebar.Footer>
      </Sidebar>

      <MaxLetsForm
        open={openSidebarIntegration === 'MaxLets'}
        onClose={() => toggleOpenSidebarIntegration(null)}
      />
    </>
  );
};

export default ConfigsPage;
