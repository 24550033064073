import React, { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Input,
  Sidebar,
  Spinner,
  Checkbox,
} from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';

interface PeriodFormProps {
  open: boolean;
  onClose: () => void;
  formState: {
    active?: boolean;
    name?: string;
    time?: string;
    position?: number;
    observation?: string;
    cut_off_week?: { time: string; previous_day: boolean };
    cut_off_saturday?: { time: string; previous_day: boolean };
    cut_off_saturday_interior?: { time: string; previous_day: boolean };
    cut_off_sunday?: { time: string; previous_day: boolean };
  };
  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  onSubmit: () => void;
  loading: boolean;
}

const PeriodForm: React.FC<PeriodFormProps> = ({
  open,
  onClose,
  formState,
  onChange,
  onSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Sidebar maxWidth="500px" padding="base" open={open} onRemove={onClose}>
      <Sidebar.Header title={`${t('Components.PeriodForm.Title')}`} />
      <Box display="grid" gap="4">
        <Checkbox
          name="active"
          checked={formState.active || false}
          onChange={onChange} // Centralizando a função
          disabled={loading}
          label={`${t('General.active')}`}
        />
        <FormField label={`${t('Components.PeriodForm.Form.Name.Label')}`}>
          <Input
            type="text"
            name="name"
            value={formState.name || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField
          label={`${t('Components.PeriodForm.Form.Time.Label')}`}
          helpText={`${t('Components.PeriodForm.Form.Time.Helper')}`}
          showHelpText={true}
        >
          <Input
            type="text"
            name="time"
            value={formState.time || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField label={`${t('Components.PeriodForm.Form.Position.Label')}`}>
          <Input
            type="number"
            name="position"
            value={formState.position || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField
          label={`${t('Components.PeriodForm.Form.Observation.Label')}`}
        >
          <Input
            type="text"
            name="observation"
            value={formState.observation || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>

        {/* Corte Semanal */}
        <FormField
          label={`${t('Components.PeriodForm.Form.CutOffWeek.Label')}`}
        >
          <Box display="flex" gap="4" alignItems="center">
            <Box width="100px" flex="0 0 100px">
              <Input
                type="time"
                name="cut_off_week.time"
                value={formState.cut_off_week?.time || ''}
                onChange={onChange}
                disabled={loading}
              />
            </Box>
            <Checkbox
              name="cut_off_week.previous_day"
              checked={formState.cut_off_week?.previous_day || false}
              onChange={onChange}
              disabled={loading}
              label={`${t('General.previous_day')}`}
            />
          </Box>
        </FormField>

        {/* Corte Sábado */}
        <FormField
          label={`${t('Components.PeriodForm.Form.CutOffSaturday.Label')}`}
        >
          <Box display="flex" gap="4" alignItems="center">
            <Box width="100px" flex="0 0 100px">
              <Input
                type="time"
                name="cut_off_saturday.time"
                value={formState.cut_off_saturday?.time || ''}
                onChange={onChange}
                disabled={loading}
              />
            </Box>
            <Checkbox
              name="cut_off_saturday.previous_day"
              checked={formState.cut_off_saturday?.previous_day || false}
              onChange={onChange}
              disabled={loading}
              label={`${t('General.previous_day')}`}
            />
          </Box>
        </FormField>

        {/* Corte Sábado Interior */}
        <FormField
          label={`${t('Components.PeriodForm.Form.CutOffSaturdayInterior.Label')}`}
        >
          <Box display="flex" gap="4" alignItems="center">
            <Box width="100px" flex="0 0 100px">
              <Input
                type="time"
                name="cut_off_saturday_interior.time"
                value={formState.cut_off_saturday_interior?.time || ''}
                onChange={onChange}
                disabled={loading}
              />
            </Box>
            <Checkbox
              name="cut_off_saturday_interior.previous_day"
              checked={
                formState.cut_off_saturday_interior?.previous_day || false
              }
              onChange={onChange}
              disabled={loading}
              label={`${t('General.previous_day')}`}
            />
          </Box>
        </FormField>

        {/* Corte Domingo */}
        <FormField
          label={`${t('Components.PeriodForm.Form.CutOffSunday.Label')}`}
        >
          <Box display="flex" gap="4" alignItems="center">
            <Box width="100px" flex="0 0 100px">
              <Input
                type="time"
                name="cut_off_sunday.time"
                value={formState.cut_off_sunday?.time || ''}
                onChange={onChange}
                disabled={loading}
              />
            </Box>
            <Checkbox
              name="cut_off_sunday.previous_day"
              checked={formState.cut_off_sunday?.previous_day || false}
              onChange={onChange}
              disabled={loading}
              label={`${t('General.previous_day')}`}
            />
          </Box>
        </FormField>

        <Button onClick={onSubmit} appearance="primary" disabled={loading}>
          {loading ? <Spinner color="currentColor" size="small" /> : 'Salvar'}
        </Button>
      </Box>
    </Sidebar>
  );
};

export default PeriodForm;
