import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Icon,
  Input,
  Textarea,
  useToast,
} from '@nimbus-ds/components';
import { FormField, Layout, Page } from '@nimbus-ds/patterns';
import nexo from '../../nexoClient';
import { navigateHeader } from '@tiendanube/nexo';

import { useTranslation } from 'react-i18next';
import { PrinterIcon } from '@nimbus-ds/icons';
import imageBg from '../../lib/assets/model-message-card.png';
import { useParams } from 'react-router-dom';
import apiInstance from '../../utils/apiUtils';

interface IMessageData {
  text: string;
  order_id: string;
  fontSize: number;
}

function MessageCardPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const { order_id } = useParams<{ order_id: string }>();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
  }, []);

  useEffect(() => {
    if (order_id) {
      fetchOrderInfo();
    }
    setLoading(false);
  }, [order_id]);

  const fetchOrderInfo = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(
        `/shipping-info/order/${order_id}/card-message`,
      );
      if (result.data) {
        const orderCardInfo = result.data;
        setMessageData({
          ...messageData,
          text: orderCardInfo.message || '',
          order_id: `#${orderCardInfo.order_number}`,
        });
      }
    } catch (error) {
      addToast({
        id: new Date().toISOString(),
        type: 'danger',
        text: 'Não conseguimos recuperar os dados do pedido',
      });
    } finally {
      setLoading(false);
    }
  };

  const [messageData, setMessageData] = useState<IMessageData>({
    text: '',
    order_id: '',
    fontSize: 14,
  });

  const handlePrint = async () => {
    const normalizedText = messageData.text
      .trim() // Remove espaços extras no início e no final
      .replace(/\n/g, '<br>')
      .replace(/^\s+|\s+$/gm, ''); // Remove espaços adicionais nas linhas
    const printContent = `
      <div id="card-message-to-print" style="position: relative; width: 21cm; height: 29.7cm; padding: 1cm; box-sizing: border-box;">
        <!-- Área do Cartão -->
        <div style="position: absolute; top: 17.8cm; left: 50%; transform: translateX(-50%); width: 10.5cm; height: 10cm; box-sizing: border-box; padding: 0.5cm; display: flex; align-items: center; justify-content: center;">
          <div style="width: 100%; height: 100%; overflow: hidden; font-size: ${messageData.fontSize}px; text-align: center; color: #000; font-family: 'Aleo', serif; font-weight: bold; white-space: pre-wrap; word-break: break-word; margin: 0; padding: 0; line-height: 1.2; display: flex; align-items: center; justify-content: center;">${normalizedText}</div>
        </div>
        
        <!-- Número do Pedido -->
        <div style="position: absolute; top: 28.8cm; left: 50%; transform: translateX(-50%); width: 100%; text-align: center; font-size: 14px; color: #000; font-family: 'Aleo', serif; font-weight: bold;">
          ${messageData.order_id}
        </div>
      </div>
    `;

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Impressão</title>
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@1,700&display=swap');
              @media print {
                body {
                  margin: 0;
                }
                #card-message-to-print {
                  margin: 0 auto;
                }
                /* Ocultar títulos e paginação */
                @page {
                  margin: 0;
                }
                header, footer, .page-title {
                  display: none;
                }
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            ${printContent}
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  return (
    <>
      <Page>
        <Page.Header title="Cartão de Mensagem" />
        <Page.Body>
          <Layout columns="2 - symmetric">
            <Layout.Section>
              <Card>
                <Card.Body>
                  {/* Prévia do layout de impressão */}
                  <div
                    style={{
                      background: `url(${imageBg}) no-repeat scroll center #fff`,
                      position: 'relative',
                      width: '21cm',
                      height: '30.7cm',
                      border: '1px solid #eee',
                      padding: '1cm',
                      boxSizing: 'border-box',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '18cm',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '10.5cm',
                        height: '10cm',
                        border: '1px solid #eee',
                        padding: '0.5cm',
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div
                        style={{
                          width: '9.5cm',
                          height: '10cm',
                          fontFamily: `"Aleo", serif`,
                          fontSize: `${messageData.fontSize}px`,
                          fontWeight: `700`,
                          textAlign: 'center',
                          fontStyle: 'italic',
                          color: '#000',
                          whiteSpace: 'pre-wrap', // Respeita espaços e quebras de linha
                          wordBreak: 'break-word', // Quebra palavras grandes
                          overflowWrap: 'break-word', // Alternativa para forçar quebra
                          display: 'flex', // Flexbox no contêiner interno
                          alignItems: 'center', // Centraliza verticalmente
                          justifyContent: 'center', // Centraliza horizontalmente
                        }}
                        dangerouslySetInnerHTML={{
                          __html: messageData.text.replace(/\n/g, '<br>'),
                        }}
                      ></div>
                    </div>

                    <div
                      style={{
                        position: 'absolute',
                        top: '29.6cm',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: '100%',
                        textAlign: 'center',
                        fontSize: '16px',
                        color: '#000',
                        fontFamily: `"Aleo", serif`,
                        fontWeight: 'bold',
                      }}
                    >
                      {messageData.order_id}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Box position="sticky" top="10px">
                <Card>
                  <Card.Header title="Configurações" />
                  <Card.Body>
                    <Box display="grid" gap="6">
                      <Box
                        display="flex"
                        gap="6"
                        justifyContent="space-between"
                      >
                        <FormField label="Número do Pedido">
                          <Input
                            id="order-id"
                            name="order-id"
                            value={messageData.order_id}
                            disabled={loading}
                            onChange={(e) =>
                              setMessageData({
                                ...messageData,
                                order_id: e.target.value,
                              })
                            }
                          />
                        </FormField>
                        <FormField label="Tamanho da Fonte">
                          <Input
                            type="number"
                            id="font-size"
                            name="font-size"
                            disabled={loading}
                            value={messageData.fontSize}
                            onChange={(e) =>
                              setMessageData({
                                ...messageData,
                                fontSize: +e.target.value,
                              })
                            }
                          />
                        </FormField>
                      </Box>
                      <FormField label="Mensagem do cartão">
                        <Textarea
                          id="card-message"
                          name="card-mensage"
                          lines={20}
                          value={messageData.text}
                          disabled={loading}
                          onChange={(e) =>
                            setMessageData({
                              ...messageData,
                              text: e.target.value,
                            })
                          }
                        />
                      </FormField>
                    </Box>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      appearance="primary"
                      onClick={handlePrint}
                      disabled={loading}
                    >
                      <Icon color="currentColor" source={<PrinterIcon />} />
                      Imprimir cartão
                    </Button>
                  </Card.Footer>
                </Card>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default MessageCardPage;
