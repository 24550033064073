import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  IconButton,
  Text,
  useToast,
  Sidebar,
  Button,
  Input,
  Title,
  Tag,
  Spinner,
  Modal,
} from '@nimbus-ds/components';
import { PlusCircleIcon, EditIcon, TrashIcon } from '@nimbus-ds/icons';
import apiInstance from '../../utils/apiUtils';
import {
  IStoreShelves,
  IShelf,
  EmptyStoreShelves,
  IShelfForm,
} from '../../lib/interfaces/shelves.interface';
import { EmptyMessage, FormField, Layout, Page } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';

const ShelvesPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [shelves, setShelves] = useState<IStoreShelves>(EmptyStoreShelves);
  const [selectedShelf, setSelectedShelf] = useState<IShelf | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [formShelf, setFormShelf] = useState<IShelfForm | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<{
    shelfId: string;
    lineNumber: number;
    positionNumber: number;
    isOccupied: boolean;
  } | null>(null);
  const [orderId, setOrderId] = useState<string>('');

  const fetchShelves = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/shelves`);
      setShelves(result.data || EmptyStoreShelves);
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('General.Toasts.Error.GetShelves')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveShelf = async (shelfId: string) => {
    try {
      await apiInstance.delete(`/shelves/${shelfId}`);
      addToast({
        id: `success_${new Date().toISOString()}`,
        type: 'success',
        text: `${t('ShelvesPage.Toasts.RemoveShelf.Success')}`,
        position: 4,
      });
      fetchShelves();
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('ShelvesPage.Toasts.RemoveShelf.Error')}`,
        position: 4,
      });
    }
  };

  const handleSidebarOpen = (shelf: IShelf | null = null) => {
    setSelectedShelf(shelf);
    setFormShelf(
      shelf || {
        name: '',
        lines: [],
      },
    );
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSelectedShelf(null);
    setFormShelf(null);
    setIsSidebarOpen(false);
  };

  const handleSaveShelf = async () => {
    try {
      if (
        !formShelf ||
        !formShelf.lineCount ||
        !formShelf.positionsPerLine ||
        !formShelf.name
      ) {
        addToast({
          id: `error_${new Date().toISOString()}`,
          type: 'danger',
          text: `${t('ShelvesPage.Toasts.SaveShelf.Error')}`,
          position: 4,
        });
        return;
      }

      setLoadingSave(true);

      const lines = generateLinesAndPositions(
        formShelf.lineCount,
        formShelf.positionsPerLine,
      );

      const payload = {
        shelves: [
          {
            name: formShelf.name,
            lines,
          },
        ],
      };

      if (selectedShelf) {
        await apiInstance.put(`/shelves`, payload);
      } else {
        await apiInstance.post(`/shelves`, payload);
      }

      addToast({
        id: `success_${new Date().toISOString()}`,
        type: 'success',
        text: `${t('ShelvesPage.Toasts.SaveShelf.Success')}`,
        position: 4,
      });

      fetchShelves();
      handleSidebarClose();
    } catch (error: any) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: error?.response?.data?.message || `${t('General.error_message')}`,
        position: 4,
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const togglePosition = async (
    shelfId: string,
    lineNumber: number,
    positionNumber: number,
    isOccupied: boolean,
  ) => {
    if (!isOccupied) {
      // Marcar como ocupado: Abrir modal para solicitar orderId
      setCurrentPosition({ shelfId, lineNumber, positionNumber, isOccupied });
      setIsModalOpen(true);
    } else {
      // Liberar posição diretamente
      try {
        await apiInstance.patch(
          `/shelves/${shelfId}/lines/${lineNumber}/positions/${positionNumber}`,
          { isOccupied: false },
        );
        fetchShelves();
        addToast({
          id: `success_${new Date().toISOString()}`,
          type: 'success',
          text: `${t('ShelvesPage.Toasts.RemovePosition.Success')}`,
          position: 4,
        });
      } catch (error) {
        addToast({
          id: `error_${new Date().toISOString()}`,
          type: 'danger',
          text: `${t('ShelvesPage.Toasts.RemovePosition.Error')}`,
          position: 4,
        });
      }
    }
  };

  const saveOrderId = async () => {
    if (!currentPosition || !orderId) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('ShelvesPage.Toasts.AddInPosition.Alert')}`,
        position: 4,
      });
      return;
    }

    setLoadingSave(true);
    try {
      await apiInstance.patch(
        `/shelves/${currentPosition.shelfId}/lines/${currentPosition.lineNumber}/positions/${currentPosition.positionNumber}`,
        {
          isOccupied: true,
          orderId,
        },
      );
      fetchShelves();
      addToast({
        id: `success_${new Date().toISOString()}`,
        type: 'success',
        text: `${t('ShelvesPage.Toasts.AddInPosition.Success')}`,
        position: 4,
      });
      setIsModalOpen(false);
      setOrderId('');
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('ShelvesPage.Toasts.AddInPosition.Error')}`,
        position: 4,
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const generateLinesAndPositions = (
    lineCount: number,
    positionsPerLine: number,
  ) => {
    const lines = [];
    for (let i = 1; i <= lineCount; i++) {
      const positions = [];
      for (let j = 1; j <= positionsPerLine; j++) {
        positions.push({
          position: j,
          isOccupied: false,
        });
      }
      lines.push({
        lineNumber: i,
        positions,
      });
    }
    return lines;
  };

  useEffect(() => {
    fetchShelves();
  }, []);

  return (
    <Page>
      <Page.Header
        title={`${t('ShelvesPage.Title')}`}
        buttonStack={
          <Button appearance="primary" onClick={() => handleSidebarOpen()}>
            <PlusCircleIcon />
            {`${t('General.add')}`}
          </Button>
        }
      />
      <Page.Body>
        <Layout>
          <Layout.Section>
            <Box display="flex" flexDirection="row" flexWrap="wrap" gap="4">
              {loading ? (
                [...Array(3)].map((_, index) => (
                  <Box width="33.33333%" key={index}>
                    <Card>
                      <Card.Header>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="2"
                        >
                          <Title.Skeleton />
                          <Box display="flex" gap="2">
                            <IconButton.Skeleton />
                            <IconButton.Skeleton />
                          </Box>
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        {[...Array(3)].map((_, index2) => (
                          <Box display="grid" gap="2" key={index2}>
                            <Box display="flex" gap="2" justifyContent="center">
                              <Text.Skeleton width="20%" />
                            </Box>
                            <Box display="flex" gap="2" justifyContent="center">
                              <Text.Skeleton width="20%" />
                              <Text.Skeleton width="20%" />
                              <Text.Skeleton width="20%" />
                            </Box>
                          </Box>
                        ))}
                      </Card.Body>
                    </Card>
                  </Box>
                ))
              ) : shelves.shelves.length > 0 ? (
                shelves.shelves.map((shelf) => (
                  <Box width="auto" key={shelf._id}>
                    <Card padding="none">
                      <Card.Header padding="base">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          gap="2"
                        >
                          <Title as="h4">{shelf.name}</Title>
                          <Box display="flex" gap="2">
                            <IconButton
                              size="26px"
                              source={<EditIcon />}
                              onClick={() => handleSidebarOpen(shelf)}
                              title={`${t('General.edit')}`}
                            />
                            <IconButton
                              size="26px"
                              source={<TrashIcon />}
                              onClick={() => handleRemoveShelf(shelf._id!)}
                              title={`${t('General.remove')}`}
                            />
                          </Box>
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        <Box display="grid">
                          {shelf.lines.map((line) => (
                            <Box
                              display="grid"
                              gap="1"
                              key={line.lineNumber}
                              borderWidth="none"
                              borderTopWidth="1"
                              borderStyle="solid"
                              borderColor="neutral-surfaceDisabled"
                              paddingX="4"
                              paddingTop="2"
                              paddingBottom="3"
                            >
                              <Text
                                fontSize="caption"
                                fontWeight="bold"
                                textAlign="center"
                              >
                                {`${t(`General.shelf`)} ${line.lineNumber}`}
                              </Text>
                              <Box
                                display="flex"
                                gap="2"
                                justifyContent="space-between"
                              >
                                {line.positions.map((position) => (
                                  <Tag
                                    key={position.position}
                                    appearance={
                                      position.isOccupied ? 'danger' : 'success'
                                    }
                                    onClick={() =>
                                      togglePosition(
                                        shelf._id!,
                                        line.lineNumber,
                                        position.position,
                                        position.isOccupied,
                                      )
                                    }
                                  >
                                    {position.isOccupied
                                      ? `#${position.orderId}`
                                      : `Posição ${position.position}`}
                                  </Tag>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Card.Body>
                    </Card>
                  </Box>
                ))
              ) : (
                <EmptyMessage
                  title="Nenhuma prateleira encontrada"
                  text="Adicione prateleiras para começar a organizar seu estoque."
                />
              )}
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>

      <Modal
        open={isModalOpen}
        onDismiss={() => {
          setIsModalOpen(false);
          setOrderId('');
        }}
      >
        <Modal.Header title="Informar Pedido" />
        <Modal.Body padding="none">
          <FormField label="Número do Pedido">
            <Input
              append={
                <Text fontWeight="bold" fontSize="highlight">
                  #
                </Text>
              }
              value={orderId}
              disabled={loadingSave}
              onChange={(e) => setOrderId(e.target.value)}
              placeholder="Ex.: 123456"
            />
          </FormField>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loadingSave}
            appearance="neutral"
            onClick={() => {
              setIsModalOpen(false);
              setOrderId('');
            }}
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            onClick={saveOrderId}
            disabled={loadingSave}
          >
            {loadingSave ? <Spinner size="small" /> : <></>}
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Sidebar
        padding="base"
        open={isSidebarOpen}
        onRemove={handleSidebarClose}
      >
        <Sidebar.Header
          title={
            selectedShelf
              ? 'Editar Estante/Organizadora'
              : 'Adicionar Estante/Organizadora'
          }
        />
        <Sidebar.Body>
          <Box display="grid" gap="4">
            <FormField label="Nome da Estante/Organizadora">
              <Input
                value={formShelf?.name || ''}
                disabled={loadingSave}
                onChange={(e) =>
                  setFormShelf((prev) =>
                    prev ? { ...prev, name: e.target.value } : null,
                  )
                }
                placeholder="Ex.: Estante A"
              />
            </FormField>
            <FormField label="Quantidade de Prateleiras">
              <Input
                type="number"
                disabled={loadingSave}
                onChange={(e) =>
                  setFormShelf((prev) =>
                    prev
                      ? { ...prev, lineCount: parseInt(e.target.value, 10) }
                      : null,
                  )
                }
                placeholder="Ex.: 3"
              />
            </FormField>
            <FormField label="Quantidade de Posições por Prateleira">
              <Input
                type="number"
                disabled={loadingSave}
                onChange={(e) =>
                  setFormShelf((prev) =>
                    prev
                      ? {
                          ...prev,
                          positionsPerLine: parseInt(e.target.value, 10),
                        }
                      : null,
                  )
                }
                placeholder="Ex.: 5"
              />
            </FormField>
          </Box>
        </Sidebar.Body>
        <Sidebar.Footer>
          <Button
            onClick={handleSaveShelf}
            appearance="primary"
            disabled={loadingSave}
          >
            {loadingSave ? <Spinner size="small" /> : <></>}
            Salvar
          </Button>
        </Sidebar.Footer>
      </Sidebar>
    </Page>
  );
};

export default ShelvesPage;
