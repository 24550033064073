export interface INextOrderPayloadWaitingPacking {
  package_file?: File;
  package_base64: string;
  package_size: string;
}

//order.next_action === 'waiting_packing'
export const EmpityNextOrderWaitingPacking: INextOrderPayloadWaitingPacking = {
  package_base64: '',
  package_size: '',
};
export const OrderNextActionName = {
  AGUARDANDO_PREPARACAO: 'Aguardando Preparação',
  EM_PREPARACAO: 'Pedidos Em Preparação',
  AGUARDANDO_RETIRADA: 'Pedidos Prontos',
  EM_TRANSITO: 'Em Rota de Entrega',
  CONCLUIDO: 'Pedidos Entregues',
};

export interface IShippingOrderInfo {
  _id?: string;
  store_id: number;
  cart_id: number;
  status: 'cart' | 'order';
  customer_id?: number;
  zipcode?: string;
  cart_created_at?: Date; // Alterado para Date
  cart_updated_at?: Date; // Alterado para Date
  order_created_at?: Date; // Alterado para Date
  delivery_date?: Date; // Alterado para Date
  complete_date: Date; // Alterado para Date
  order_info_send_at: Date; // Alterado para Date
  shipping_cost?: number; // Tornado opcional
  shipping_period_info?: string;
  json_info?: string;
  error_data?: string;
  notes?: string; // Observações adicionais
  customer_message?: string; // Observações adicionais
  recipient_name?: string; // Observações adicionais
  recipient_phone?: string; // Observações adicionais
  customer_send_as_anonymouns?: boolean; // Observações adicionais
  carrier_external_id?: string; // Observações adicionais
  period_code?: string; // Observações adicionais
  photo_packaged?: string;
  size_packaged?: string;
  slot_packaged?: string;
  ns_order?: { [key: string]: any };
  ns_custom_fields?: { [key: string]: any };
}

export interface IOrder {
  id: number;
  token: string;
  store_id: number;
  contact_email: string;
  contact_name: string;
  contact_phone: string;
  contact_identification: string;
  shipping_min_days: number | null;
  shipping_max_days: number | null;
  billing_name: string;
  billing_phone: string;
  billing_address: string;
  billing_number: string;
  billing_floor: string;
  billing_locality: string;
  billing_zipcode: string;
  billing_city: string;
  billing_province: string;
  billing_country: string;
  billing_customer_type: string | null;
  billing_business_name: string | null;
  billing_trade_name: string | null;
  billing_state_registration: string | null;
  billing_fiscal_regime: string | null;
  billing_business_activity: string | null;
  billing_invoice_use: string | null;
  billing_document_type: string | null;
  shipping_cost_owner: string;
  shipping_cost_customer: string;
  coupon: any[];
  promotional_discount: {
    id: number | null;
    store_id: number;
    order_id: number;
    created_at: string;
    total_discount_amount: string;
    contents: any[];
    promotions_applied: any[];
  };
  subtotal: string;
  discount: string;
  discount_coupon: string | null;
  discount_gateway: string;
  total: string;
  total_usd: string;
  checkout_enabled: boolean;
  weight: string;
  currency: string;
  language: string;
  gateway: string;
  gateway_id: string;
  gateway_name: string;
  shipping: string;
  shipping_option: string;
  shipping_option_code: string;
  shipping_option_reference: string;
  shipping_pickup_details: any | null;
  shipping_tracking_number: string | null;
  shipping_tracking_url: string | null;
  shipping_store_branch_name: string | null;
  shipping_store_branch_extra: string | null;
  shipping_pickup_type: string;
  shipping_suboption: any[];
  extra: Record<string, any>;
  storefront: string;
  note: string;
  created_at: string;
  updated_at: string;
  completed_at: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  next_action: string;
  payment_details: {
    method: string;
    credit_card_company: string;
    installments: number;
  };
  attributes: any[];
  free_shipping_config: any | null;
  payment_count: number;
  payment_status: string;
  order_origin: string | null;
  same_billing_and_shipping_address: boolean;
  customer: {
    id: number;
    name: string;
    email: string;
    identification: string;
    phone: string;
    note: string | null;
    default_address: IAddress;
    addresses: IAddress[];
    billing_name: string;
    billing_phone: string;
    billing_address: string;
    billing_number: string;
    billing_floor: string;
    billing_locality: string;
    billing_zipcode: string;
    billing_city: string;
    billing_province: string;
    billing_country: string;
    extra: Record<string, any>;
    total_spent: string;
    total_spent_currency: string;
    last_order_id: number;
    active: boolean;
    first_interaction: string;
    created_at: string;
    updated_at: string;
    customer_type: string;
    business_name: string;
    trade_name: string;
    state_registration: string;
    fiscal_regime: string;
    invoice_use: string;
    document_type: string;
    business_activity: string;
    accepts_marketing: boolean;
    accepts_marketing_updated_at: string;
  };
  products: IProduct[];
  customer_visit: {
    created_at: string;
    landing_page: string;
    utm_parameters: {
      utm_campaign: string | null;
      utm_content: string | null;
      utm_medium: string | null;
      utm_source: string | null;
      utm_term: string | null;
    };
  };
  fulfillments: IFulfillment[];
  number: number;
  cancel_reason: string | null;
  owner_note: string;
  cancelled_at: string | null;
  closed_at: string | null;
  read_at: string | null;
  status: string;
  gateway_link: string | null;
  has_shippable_products: boolean;
  shipping_carrier_name: string;
  shipping_address: IAddress;
  shipping_status: string;
  shipped_at: string | null;
  paid_at: string;
  landing_url: string;
  client_details: {
    browser_ip: string;
    user_agent: string;
  };
  app_id: string | null;
  period: string;
  deliveryDate: string;
  sendAsAnonymous: string;
  recipientName: string;
  recipientPhone: string;
  period_object: IPeriodObject;
  period_order: number;
  distribution_center: {
    id: string;
    base: string;
    zip_band: {
      label: string;
      start: string;
      end: string;
    };
  };
}

// Sub-interfaces
interface IAddress {
  address: string;
  city: string;
  country: string;
  created_at: string;
  default: boolean;
  floor: string;
  id: number;
  locality: string;
  name: string;
  number: string;
  phone: string;
  province: string;
  updated_at: string;
  zipcode: string;
  customs?: any | null;
}

interface IProduct {
  id: number;
  depth: string;
  height: string;
  name: string;
  name_without_variants: string | null;
  price: string;
  compare_at_price: string;
  product_id: number;
  image: {
    id: number;
    product_id: number;
    src: string;
    position: number;
    alt: any[];
    height: number;
    width: number;
    thumbnails_generated: number;
    created_at: string;
    updated_at: string;
  };
  quantity: number;
  free_shipping: boolean;
  weight: string;
  width: string;
  variant_id: number;
  variant_values: any[];
  properties: any[];
  sku: string;
  barcode: string | null;
  cost: string | null;
}

interface IFulfillment {
  id: string;
  number: string;
  assigned_location: {
    location_id: string;
    name: string;
  };
  status: string;
  shipping: {
    type: string;
    extras: { shippable: boolean };
    carrier: { name: string };
    option: { name: string };
  };
  tracking_info: {
    code: string;
  };
}

interface IPeriodObject {
  _id: string;
  store_id: number;
  week_external_id: number;
  saturday_external_id: number;
  sunday_external_id: number;
  code: string;
  active: boolean;
  name: string;
  time: string;
  observation: string;
  position: number;
  cut_off_week: ICutOffDetails;
  cut_off_saturday: ICutOffDetails;
  cut_off_saturday_interior: ICutOffDetails;
  cut_off_sunday: ICutOffDetails;
  createdAt: string;
  updatedAt: string;
  id: string;
}

interface ICutOffDetails {
  time: string;
  previous_day: boolean;
  _id: string;
}
