import { useEffect, useState } from 'react';
import QRCode from 'qrcode';
import { format, parseISO } from 'date-fns';

import {
  Box,
  Card,
  Link,
  Tag,
  Text,
  Title,
  IconButton,
  useToast,
  Button,
  Modal,
  FileUploader,
  Spinner,
  Select,
  Alert,
  Label,
  Input,
  Icon,
  Popover,
} from '@nimbus-ds/components';
import {
  Calendar,
  FormField,
  Layout,
  Page,
  ThumbnailWithAction,
} from '@nimbus-ds/patterns';
import { produce } from 'immer';
import apiInstance from '../../utils/apiUtils';
import nexo from '../../nexoClient';
import {
  ArrowRightIcon,
  CalendarIcon,
  ColorPaletteIcon,
  PrinterIcon,
  RedoIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { goTo, navigateHeaderRemove } from '@tiendanube/nexo';

import {
  defaultStoreConfig,
  IStoreConfig,
} from '../../lib/interfaces/store-config.interface';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../utils/formatUtils';
import { IDistributionCenter } from '../../lib/interfaces/distribution-center.interface';
import {
  EmpityNextOrderWaitingPacking,
  INextOrderPayloadWaitingPacking,
  IOrder,
  OrderNextActionName,
} from '../../lib/interfaces/orders.interface';
import { useNavigate } from 'react-router-dom';

type ICustomOrder = IOrder & { max_lets_order_created: boolean };

function PageMain() {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const FILTER_LS_NAME = 'distributionCentersFilter';

  const DATE_IN_LOCAL = {
    set: (date: string) => {
      try {
        const expirationTime = new Date().getTime() + 30 * 60 * 1000;
        const cacheObject = {
          data: date,
          expirationTime: expirationTime,
        };
        localStorage.setItem(`deliveryDate`, JSON.stringify(cacheObject));
      } catch (error) {
        console.warn(`set`, error);
      }
    },
    get: () => {
      const dateNow = new Date().toISOString().split('T')[0];
      try {
        const cachedData = localStorage.getItem(`deliveryDate`);

        if (!cachedData) {
          return dateNow;
        }

        if (cachedData.indexOf('expirationTime') > -1) {
          const cacheObject = JSON.parse(cachedData);

          if (
            cacheObject.expirationTime &&
            new Date().getTime() > cacheObject.expirationTime
          ) {
            localStorage.removeItem(`deliveryDate`);
            return dateNow;
          }

          return cacheObject.data;
        }

        return dateNow;
      } catch (error) {
        console.warn(`set`, error);
        return dateNow;
      }
    },
  };

  const [loading, setLoading] = useState(true);
  const [distributionCenters, setDistributionCenters] = useState<
    IDistributionCenter[]
  >([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [allOrders, setAllOrders] = useState<{ [key: number]: ICustomOrder }>(
    {},
  );
  const [columns, setColumns] = useState<{
    ordersCol1: ICustomOrder[];
    ordersCol2: ICustomOrder[];
    ordersCol3: ICustomOrder[];
    ordersCol4: ICustomOrder[];
  }>({
    ordersCol1: [],
    ordersCol2: [],
    ordersCol3: [],
    ordersCol4: [],
  });
  const [deliveryDate, setDeliveryDate] = useState<string>(DATE_IN_LOCAL.get());
  const [messageInModal, setMessageInModal] = useState<string | null>(null);
  const [loadingChangeOrder, setLoadingChangeOrder] = useState<boolean>(false);
  const [orderToNext, setOrderToNext] = useState<ICustomOrder | null>(null);
  const [payloadOrderToNext, setPayloadOrderToNext] =
    useState<INextOrderPayloadWaitingPacking | null>(null);

  const [storeConfig, setStoreConfig] = useState<IStoreConfig>({
    ...defaultStoreConfig,
  });

  const calendar = (
    <Calendar
      mode="single"
      showOutsideDays
      selected={parseISO(deliveryDate)}
      onSelect={(value) => {
        DATE_IN_LOCAL.set(value?.toISOString().split('T')[0] || '');
        setDeliveryDate(value?.toISOString().split('T')[0] || '');
      }}
      hideBorder
      fullWidthDays
      containerProps={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        maxHeight: '400px',
      }}
    />
  );

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const orderIdParam = params.get('order_id');
    const methodParam = params.get('method');

    if (orderIdParam && methodParam) {
      const orderId = parseInt(orderIdParam, 10);
      const order = allOrders[orderId];

      if (order) {
        if (order.next_action === methodParam) {
          openModalNextAction(order); // Abre o modal com o pedido correto
        } else {
          addToast({
            id: 'error_invalid_method',
            type: 'danger',
            text: `${t(`MainPage.Toasts.InvalidMethodMessage`, { order_id: orderId, action: order.next_action })}`,
            position: 4,
          });
        }
      } else {
        addToast({
          id: 'error_get_order',
          type: 'danger',
          text: `${t(`MainPage.Toasts.OrderNotFoundMessage`, { order_id: orderId })}`,
          position: 4,
        });
      }
      navigate('/?ok=true', { replace: true });
    }
  }, [allOrders, navigate]);

  useEffect(() => {
    navigateHeaderRemove(nexo);

    const getData = async () => {
      try {
        const result = await apiInstance.get(`/store-config`);
        if (result.data !== null) {
          const resData = result.data;
          setStoreConfig({ ...defaultStoreConfig, ...resData });
        }
      } catch (error) {
        setStoreConfig({ ...defaultStoreConfig });
        addToast({
          id: 'error_get' + new Date().toISOString(),
          type: 'danger',
          text: `${t('General.Toasts.Error.GetConfig')}`,
          position: 4,
        });
      }
    };

    getData();
    fetchOrders();

    try {
      const filterInCache = localStorage.getItem(FILTER_LS_NAME);
      if (filterInCache) {
        setFilterValue(filterInCache);
      }
    } catch (error) {
      console.log(`Nenhum filtro aplicado`);
    }
  }, []);

  const fetchOrders = async () => {
    try {
      resetOrderToNext();
      setLoading(true);

      const response = await apiInstance.get(
        `/shipping-info/orders?delivery_date=${DATE_IN_LOCAL.get()}`,
      );

      if (response.status !== 200) {
        addToast({
          id: 'error_get' + new Date().toISOString(),
          type: 'danger',
          text: `${t('General.Toasts.Error.GetOrders')}`,
          position: 4,
        });
        setLoading(false);
        return;
      }

      const { orders, distributionCenters } = response.data as {
        orders: ICustomOrder[];
        distributionCenters: any;
      };

      setDistributionCenters(distributionCenters);

      const orderById = orders.reduce<{ [key: number]: ICustomOrder }>(
        (acc: { [key: number]: ICustomOrder }, order: ICustomOrder) => {
          acc[order.id] = order;
          return acc;
        },
        {},
      );

      setAllOrders(orderById);

      // Atualiza as colunas de pedidos
      setColumns((prev) =>
        produce(prev, (draft: any) => {
          const addedOrders = new Set();

          const filterAndAdd = (condition: any) => (order: ICustomOrder) => {
            if (condition(order) && !addedOrders.has(order.id)) {
              addedOrders.add(order.id);
              return true;
            }
            return false;
          };

          draft.ordersCancelled = orders.filter(
            filterAndAdd(
              (order: any) => order.status === 'cancelled' || order.number <= 0,
            ),
          );

          draft.ordersCol4 = orders.filter(
            filterAndAdd(
              (order: any) =>
                order.status === 'closed' || order.next_action === 'noop',
            ),
          );

          draft.ordersCol1 = orders.filter(
            filterAndAdd(
              (order: any) => order.next_action === 'waiting_packing',
            ),
          );
          draft.ordersCol2 = orders.filter(
            filterAndAdd(
              (order: any) => order.next_action === 'waiting_shipment',
            ),
          );
          draft.ordersCol3 = orders.filter(
            filterAndAdd((order: any) => order.next_action === 'close'),
          );
        }),
      );
    } catch (error) {
      console.error('Erro ao buscar pedidos:', error);
    } finally {
      setLoading(false);
    }
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Extraindo partes da data
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam de 0
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  const handlePrint = async (order: ICustomOrder) => {
    const baseUrl = `${window.location.ancestorOrigins[0] || ''}/admin/v2/apps/14422#/qr-code/order/${order.id}/next-action`;

    const qrCodeWaitingPacking = await QRCode.toDataURL(
      `${baseUrl}/waiting_packing`,
    );

    const qrCodeWaitingShipment = await QRCode.toDataURL(
      `${baseUrl}/waiting_shipment`,
    );

    const printContent = `
      <div id="print-section">
        <div class="delivery-info">
          <div>
            <p class="text-g">${t(`MainPage.CommandPrint.Title`)}</p>
            <p class="text-g">#${order.number}</p>
            <p class="text-g">${formatDate(order.created_at)}</p>
          </div>
          <img src="https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=https://minhaloja.lojavirtualnuvem.com.br/admin/v2/apps/14422#/?order_id=${order.id}&method=close" alt="QR Code" width="180px" style="display: block; margin: auto 0 auto auto;">
        </div>
        <br />
        <br />
        <p>${t(`MainPage.CommandPrint.DeliveryDateLabel`)}: ${order.deliveryDate}</p>
        <p>${t(`MainPage.CommandPrint.PeriodLabel`)}: ${order.period}</p>
        <p>${t(`MainPage.CommandPrint.ClientLabel`)}: ${`${order.sendAsAnonymous}`.trim().toLowerCase() === 'sim' ? t(`General.anonymous`) : order.contact_name}</p>
        <p>${t(`General.to`)}: ${order.recipientName}</p>
        <br />
        <p>${t(`General.address`)}: ${order.shipping_address.address}, ${
          order.shipping_address.number
        } - ${order.shipping_address.locality} - ${order.shipping_address.city}/${
          order.shipping_address.province
        } - ${order.shipping_address.zipcode}</p>
        <p>${t(`General.reference`)}: ${order.shipping_address.floor}</p>
        <br />
        <table>
            <thead>
                <tr>
                    <th>${t(`General.qty`)}</th>
                    <th>${t(`General.description`)}</th>
                    <th>SKU</th>
                </tr>
            </thead>
            <tbody>
              ${order.products
                .map(
                  (item: any) =>
                    `<tr>
                        <td>${item.quantity}</td>
                        <td>${item.name}</td>
                        <td>${item.sku || ''}</td>
                    </tr>`,
                )
                .join('')}
            </tbody>
        </table>
        <br />
        <p><small><b>${t(`General.observation`)}:</b> ${order.owner_note}</small></p>
        ${
          storeConfig.printerConfig.enableQRCode
            ? `
            <br />
            <br />
            <hr />
            <br />
            <h5>${t(`MainPage.CommandPrint.QRCode1Title`)}</h5>
            <p class="text-center">${t(`MainPage.CommandPrint.QRCode1SubTitle`)}</p>
            <img src="${qrCodeWaitingPacking}" alt="QR Code" width="100px" style="display: block; margin: 5px auto;">
            <hr />
            <br />
            <h5>${t(`MainPage.CommandPrint.QRCode2Title`)}</h5>
            <img src="${qrCodeWaitingShipment}" alt="QR Code" width="100px" style="display: block; margin: 5px auto;">
          `
            : ''
        }
      </div>
    `;

    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Impressão</title>
            <style>
              /* Reset e estilos gerais */
              body {
                width: ${
                  storeConfig.printerConfig.paperWidth || '80mm'
                }; /* ou 80mm, dependendo do modelo */
                margin: 0 auto; /* Centraliza horizontalmente */
                font-size: 10px; /* Ajusta tamanho da fonte */
                line-height: 1.2; /* Melhor legibilidade */
                padding: 0;
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                color: black;
              }

              .delivery-info{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                width: 100%;
              }
              .delivery-info > div{
                width: 100%;
              }

              /* Tabela */
              table {
                border-collapse: collapse;
                border: 0 none;
                width: 100%;
                margin-top: 10px;
              }

              table th, table td {
                border: none;
                font-size: 12px;
                line-height: 14px;
                padding: 5px;
                text-align: left;
                color: black;
              }

              table th {
                font-weight: bold;
              }

              table td {
                
              }

              #print-section {
                padding: 0 5mm; /* Margens internas para o conteúdo */
              }

              /* Separadores e elementos */
              hr {
                border: none;
                border-top: 1px solid #000;
                margin: 10px 0;
              }

              img {
                display: block;
                margin: 10px auto;
              }

              p, h5, h6 {
                margin: 2px 0;
                word-wrap: break-word; /* Quebra de linha automática */
              }

              img {
                max-width: 80px; /* Reduzir imagens para caber */
              }

              p {
                font-size: 12px;
                line-height: 14px;
                font-weight: bold;
                margin: 3px 0;
              }
                
              .text-g {
                font-size: 15px;
                line-height: 18px;
                font-weight: bold;
                margin: 10px 0;
              }

              small {
                font-size: 12px;
              }

              h5 {
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                text-align: center;
                margin: 5px 0;
              }

              h6 {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                margin: 5px 0;
              }

              .text-center{
                text-align: center;
              }

              /* Estilos específicos para impressão */
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }

                @page {
                  size: 58mm auto; /* Define o tamanho do papel */
                  margin: 0; /* Remove margens padrão */
                }

                hr {
                  border: none;
                  border-top: 1px solid #000;
                }
              }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            ${printContent}
          </body>
        </html>
      `);
      printWindow.document.close();
    }
  };

  const handleNextAction = async () => {
    try {
      if (orderToNext === null) return;
      setLoadingChangeOrder(true);

      const formData = new FormData();
      if (isINextOrderPayloadWaitingPacking(payloadOrderToNext)) {
        if (
          // !payloadOrderToNext.package_file ||
          // !payloadOrderToNext.package_size ||
          payloadOrderToNext.package_size === ''
        ) {
          addToast({
            id: 'error_get' + new Date().toISOString(),
            type: 'danger',
            text: `${t('General.Toasts.Alert.UploadPhoto')}`,
            position: 4,
          });
          setLoadingChangeOrder(false);
          return;
        }
        if (payloadOrderToNext.package_file) {
          formData.append('file', payloadOrderToNext.package_file);
        }
        formData.append('package_size', payloadOrderToNext.package_size);
      }

      const result = await apiInstance.post(
        `/shipping-info/orders/${orderToNext.id}/next-action`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      if (result.status === 201 || result.status === 200) {
        if (result.status === 200) {
          const message = `${result.data.message ? result.data.message : `${t('General.Toast.Alert.VerifySlot')}`}`;
          setMessageInModal(message);
          addToast({
            id: new Date().toISOString(),
            type: 'success',
            text: message,
            position: 4,
          });
        }
        fetchOrders();
      }
    } catch (error) {
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('General.Toast.Error.UpdateOrderStatus')}`,
        position: 4,
      });
    } finally {
      setLoadingChangeOrder(false);
    }
  };

  const openModalNextAction = async (order: ICustomOrder) => {
    setOrderToNext(order);
    setPayloadOrderToNext(null);
    if (order.next_action === 'waiting_pickup') {
      setPayloadOrderToNext(EmpityNextOrderWaitingPacking);
    }
  };

  const isINextOrderPayloadWaitingPacking = (
    obj: any,
  ): obj is INextOrderPayloadWaitingPacking => {
    console.log(obj);
    return (
      obj &&
      typeof obj.package_size === 'string' &&
      typeof obj.package_base64 === 'string'
    );
  };

  const resetOrderToNext = async () => {
    setPayloadOrderToNext(null);
    setOrderToNext(null);
  };

  const renderColumn = (id: string, title: string, items: ICustomOrder[]) => (
    <Box
      key={id}
      width="280px"
      borderStyle="solid"
      borderWidth="1"
      borderRadius="2"
      borderColor="neutral-surfaceDisabled"
      padding="2"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="4"
      >
        <Title as="h6">{title}</Title>
        <Tag appearance="primary">
          <Text color="primary-textLow">{items.length}</Text>
        </Tag>
      </Box>
      {loading ? (
        <Box marginY="4">
          <Card padding="small">
            <Card.Header>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap="2">
                  <Text.Skeleton width="100px" />
                  <IconButton.Skeleton />
                </Box>
                <Tag.Skeleton />
              </Box>
            </Card.Header>
            <Card.Body>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text.Skeleton width="10%" />
                <IconButton.Skeleton />
              </Box>
              <Text.Skeleton width="40%" />
              <Text.Skeleton width="100%" />
            </Card.Body>
            <Card.Footer>
              <Box
                display="flex"
                justifyContent="space-between"
                gap="2"
                width="100%"
              >
                <Box display="grid" textAlign="left">
                  <Text.Skeleton width="5%" />
                  <Text.Skeleton width="20%" />
                </Box>
                <Box display="grid">
                  <Text.Skeleton width="5%" />
                  <Text.Skeleton width="20%" />
                </Box>
              </Box>
            </Card.Footer>
          </Card>
        </Box>
      ) : (
        items.map((item) =>
          filterValue === '' ||
          !item.distribution_center ||
          item.distribution_center.id === filterValue ? (
            <Box key={item.id} marginY="4">
              <Card padding="small">
                <Card.Header>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center" gap="2">
                      <Text fontSize="caption" fontWeight="bold">
                        <Link
                          as="button"
                          onClick={() => goTo(nexo, `/orders/${item.id}`)}
                        >
                          {`#${item.number}`}
                        </Link>
                      </Text>
                      <IconButton
                        size="25px"
                        disabled={loading}
                        source={<PrinterIcon size="small" />}
                        onClick={() => handlePrint(item)}
                      />
                      <IconButton
                        size="25px"
                        disabled={loading}
                        source={<ColorPaletteIcon size="small" />}
                        onClick={() => navigate(`/message-card/${item.id}`)}
                      />
                    </Box>
                    <Tag appearance="neutral">
                      {item.distribution_center
                        ? item.distribution_center.base
                        : '--'}
                    </Tag>
                  </Box>
                </Card.Header>
                <Card.Body>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text fontSize="caption">
                      {t('General.value')}:{' '}
                      <Text as="span" fontSize="caption" fontWeight="bold">
                        {!isNaN(Number(item.total))
                          ? Number(item.total).toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : ''}
                      </Text>
                    </Text>
                    <IconButton
                      size="25px"
                      disabled={loading}
                      source={<ArrowRightIcon size="small" />}
                      // onClick={() => handleNextAction(item)}
                      onClick={() => openModalNextAction(item)}
                    />
                  </Box>
                  <Text fontSize="caption">
                    {t('General.type')}:{' '}
                    <Text as="span" fontSize="caption" fontWeight="bold">
                      {item.shipping_option?.split(' - ')[0]}
                    </Text>
                  </Text>
                  <Text fontSize="caption">
                    {t('General.client')}:{' '}
                    <Text as="span" fontSize="caption" fontWeight="bold">
                      {item.recipientName &&
                      `${item.recipientName}`.trim() !== ''
                        ? item.recipientName
                        : item.contact_name}
                    </Text>
                  </Text>
                  {item.max_lets_order_created && (
                    <Link
                      as="a"
                      href={`https://max-v2.lets.delivery/orders/OD-${storeConfig.store_id}-${item.id}`}
                      target="_blank"
                      appearance="neutral"
                    >
                      Pedido na Let`s Max
                    </Link>
                  )}
                </Card.Body>
                <Card.Footer>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap="2"
                    width="100%"
                  >
                    <Box display="grid" textAlign="left">
                      <Text as="span" fontSize="caption">
                        {t('General.placed')}
                      </Text>
                      <Text as="span" fontSize="caption" fontWeight="bold">
                        {formatTime(item.paid_at)}
                      </Text>
                    </Box>
                    <Box display="grid">
                      <Text textAlign="right" as="span" fontSize="caption">
                        {t('General.delivery')}{' '}
                      </Text>
                      <Text
                        textAlign="right"
                        as="span"
                        fontSize="caption"
                        fontWeight="bold"
                      >
                        {item.period_object?.time || '--'}
                      </Text>
                    </Box>
                  </Box>
                </Card.Footer>
              </Card>
            </Box>
          ) : (
            <></>
          ),
        )
      )}
    </Box>
  );

  const toogleFilter = (distributionCenter: IDistributionCenter) => {
    if (!distributionCenter._id) return;
    try {
      if (filterValue === distributionCenter._id) {
        setFilterValue('');
        localStorage.removeItem(FILTER_LS_NAME);
      } else {
        setFilterValue(distributionCenter._id);
        localStorage.setItem(FILTER_LS_NAME, distributionCenter._id);
      }
    } catch (error) {
      console.log(`error toogle filter button`, error);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isINextOrderPayloadWaitingPacking(payloadOrderToNext)) return;

    const file = e.target.files?.[0];
    if (file) {
      // Verifica o tamanho do arquivo
      if (file.size > 5 * 1024 * 1024) {
        // 5MB limite
        addToast({
          id: 'error_image' + new Date().toISOString(),
          type: 'danger',
          text: `${t('General.Toast.Alert.UploadPhotoMaxSize')}`,
          position: 4,
        });
        return;
      }

      // Verifica o formato
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
      if (!validTypes.includes(file.type)) {
        addToast({
          id: 'error_image' + new Date().toISOString(),
          type: 'danger',
          text: `${t('General.Toast.Alert.UploadPhotoFormats')}`,
          position: 4,
        });
        return;
      }

      const reader = new FileReader();
      payloadOrderToNext.package_file = file;
      reader.onloadend = () => {
        const base64String = reader.result as string;
        payloadOrderToNext.package_base64 = base64String;
        setPayloadOrderToNext({ ...payloadOrderToNext });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Page>
        <Page.Header title={`${t('MainPage.Title')}`} />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box
                display="flex"
                gap="4"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  gap="2"
                  alignItems="center"
                  overflowX="auto"
                  overflowY="visible"
                >
                  {distributionCenters.length ? (
                    distributionCenters.map((item) => (
                      <Button
                        key={item._id}
                        appearance={
                          filterValue === item._id ? 'primary' : 'neutral'
                        }
                        onClick={() => toogleFilter(item)}
                      >
                        {item.base}
                      </Button>
                    ))
                  ) : (
                    <></>
                  )}
                </Box>
                <Box display="flex" gap="1" alignItems="center">
                  <Label>{t('MainPage.Filters.DateLabel')}</Label>
                  <Popover content={calendar} padding="none" arrow={false}>
                    <Input
                      readOnly
                      value={
                        deliveryDate &&
                        format(parseISO(deliveryDate), 'dd/MM/yyyy')
                      }
                      placeholder="Select a date"
                      append={
                        <Icon
                          color="neutral-textLow"
                          source={<CalendarIcon />}
                        />
                      }
                      appendPosition="end"
                    />
                  </Popover>

                  <Button appearance="neutral" onClick={fetchOrders}>
                    <Icon source={<RedoIcon />} />
                  </Button>
                </Box>
              </Box>
              <Box
                display="flex"
                gap="2"
                alignItems="stretch"
                overflowX="auto"
                overflowY="visible"
              >
                {renderColumn(
                  'ordersCol1',
                  `${t(OrderNextActionName.AGUARDANDO_PREPARACAO)}`,
                  columns.ordersCol1,
                )}
                {renderColumn(
                  'ordersCol2',
                  `${t(OrderNextActionName.AGUARDANDO_RETIRADA)}`,
                  columns.ordersCol2,
                )}
                {renderColumn(
                  'ordersCol3',
                  `${t(OrderNextActionName.EM_TRANSITO)}`,
                  columns.ordersCol3,
                )}
                {renderColumn(
                  'ordersCol4',
                  `${t(OrderNextActionName.CONCLUIDO)}`,
                  columns.ordersCol4,
                )}
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
      <Modal
        open={messageInModal !== null}
        onDismiss={() => setMessageInModal(null)}
      >
        <Modal.Header title={`${t('General.attention')}`} />
        <Modal.Body padding="none">
          <Alert appearance="neutral">{messageInModal}</Alert>
        </Modal.Body>
      </Modal>
      <Modal open={orderToNext !== null} onDismiss={resetOrderToNext}>
        <Modal.Header title={`${t('General.change_order_status')}`} />
        <Modal.Body padding="none">
          {isINextOrderPayloadWaitingPacking(payloadOrderToNext) && (
            <Box display="flex" gap="6">
              <Box flex="1 1 110px" width="110px">
                <FormField label={`${t('General.order_photo')}`}>
                  {payloadOrderToNext.package_base64 &&
                  payloadOrderToNext.package_base64 !== '' ? (
                    <Box boxSizing="border-box">
                      <ThumbnailWithAction
                        thumbnail={{
                          aspectRatio: '1/1',
                          width: '110px',
                          alt: 'preview',
                          src: payloadOrderToNext.package_base64,
                        }}
                      >
                        <IconButton
                          source={<TrashIcon />}
                          size="2rem"
                          onClick={() => {
                            payloadOrderToNext.package_base64 = '';
                            delete payloadOrderToNext.package_file;
                            setPayloadOrderToNext({ ...payloadOrderToNext });
                          }}
                        />
                      </ThumbnailWithAction>
                    </Box>
                  ) : (
                    <FileUploader
                      aspectRatio="none"
                      flexDirection="row"
                      height="110px"
                      accept="image/jpeg,image/gif,image/png,image/webp"
                      placeholder={`${t('General.send')}`}
                      width="110px"
                      onChange={handleImageChange}
                    />
                  )}
                </FormField>
              </Box>
              <Box flex="1 1 100%" width="100%">
                <FormField label={`${t('General.size')}`}>
                  <Select
                    width="100%"
                    disabled={loadingChangeOrder}
                    name="package_size"
                    id="package_size"
                    value={payloadOrderToNext.package_size}
                    onChange={(e) => {
                      payloadOrderToNext.package_size = e.target.value;
                      setPayloadOrderToNext({ ...payloadOrderToNext });
                    }}
                  >
                    <Select.Option
                      disabled={true}
                      value={``}
                      label={t('General.select')}
                    />
                    <Select.Option value="P" label="P" />
                    <Select.Option value="M" label="M" />
                    <Select.Option value="G" label="G" />
                    <Select.Option value="XG" label="XG" />
                    <Select.Option value="CARRO" label="CARRO" />
                  </Select>
                </FormField>
              </Box>
            </Box>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button
              disabled={loadingChangeOrder}
              appearance="neutral"
              onClick={resetOrderToNext}
            >
              {t('General.cancel')}
            </Button>
            <Button
              appearance="primary"
              onClick={handleNextAction}
              disabled={loadingChangeOrder}
            >
              {loadingChangeOrder ? <Spinner size="small" /> : <></>}
              {t('General.confirm')}
            </Button>
          </Box>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PageMain;
