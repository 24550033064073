import { useEffect, useState } from 'react';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';

import {
  Box,
  useToast,
  Button,
  Icon,
  Alert,
  IconButton,
  FileUploader,
  Select,
  Spinner,
  Text,
} from '@nimbus-ds/components';

import apiInstance from '../../utils/apiUtils';
import nexo from '../../nexoClient';
import { navigateHeader } from '@tiendanube/nexo';

import {
  defaultStoreConfig,
  IStoreConfig,
} from '../../lib/interfaces/store-config.interface';
import { useTranslation } from 'react-i18next';
import {
  EmpityNextOrderWaitingPacking,
  INextOrderPayloadWaitingPacking,
  IOrder,
  OrderNextActionName,
} from '../../lib/interfaces/orders.interface';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FormField,
  Layout,
  Page,
  ThumbnailWithAction,
} from '@nimbus-ds/patterns';
import { ArrowLeftIcon, TrashIcon } from '@nimbus-ds/icons';

function QRCodePage() {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { order_id, next_action } = useParams();

  const [loading, setLoading] = useState(true);
  const [allowChange, setAllowChange] = useState(false);
  const [changeFinished, setChangeFinished] = useState(false);
  const [loadingChangeOrder, setLoadingChangeOrder] = useState<boolean>(false);
  const [orderToNext, setOrderToNext] = useState<IOrder | null>(null);
  const [message, setMessage] = useState<{
    type: 'error' | 'alert';
    message: string;
  } | null>(null);
  const [payloadOrderToNext, setPayloadOrderToNext] =
    useState<INextOrderPayloadWaitingPacking | null>(null);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('General.back')}` });
  }, []);

  const [storeConfig, setStoreConfig] = useState<IStoreConfig>({
    ...defaultStoreConfig,
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchOrder();
  }, []);

  const fetchOrder = async () => {
    try {
      setLoading(true);
      setMessage(null);
      const response = await apiInstance.get(
        `/shipping-info/order/${order_id}`,
      );

      if (response.status !== 200) {
        setMessage({
          type: 'error',
          message: `Erro ao consultar pedido`,
        });
        setLoading(false);
        return;
      }

      const { order, store_config } = response.data as {
        order: IOrder;
        store_config: IStoreConfig;
      };

      if (order.next_action !== next_action) {
        setMessage({
          type: 'error',
          message: `Ação inválida para este pedido!`,
        });
        return;
      }

      setOrderToNext(order);
      setStoreConfig(store_config);
      setPayloadOrderToNext(null);

      if (order.next_action === 'waiting_packing') {
        setPayloadOrderToNext(EmpityNextOrderWaitingPacking);
      }

      setAllowChange(true);
    } catch (error) {
      setMessage({
        type: 'error',
        message: `Erro ao buscar pedido`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNextAction = async () => {
    try {
      if (orderToNext === null) return;
      setLoadingChangeOrder(true);

      const formData = new FormData();
      if (isINextOrderPayloadWaitingPacking(payloadOrderToNext)) {
        if (
          !payloadOrderToNext.package_file ||
          !payloadOrderToNext.package_size ||
          payloadOrderToNext.package_size === ''
        ) {
          setMessage({
            type: 'alert',
            message: `Informe o tamanho e uma foto do pedido finalizado!`,
          });
          setLoadingChangeOrder(false);
          return;
        }
        if (payloadOrderToNext.package_file) {
          formData.append('file', payloadOrderToNext.package_file);
        }
        formData.append('package_size', payloadOrderToNext.package_size);
      }

      const result = await apiInstance.post(
        `/shipping-info/orders/${orderToNext.id}/next-action`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      if (result.status === 201 || result.status === 200) {
        if (result.status === 200) {
          setMessage({
            type: 'alert',
            message: `${result.data.message ? result.data.message : 'Verifique qual local foi separado para este pedido.'}`,
          });
          setChangeFinished(true);
        }
      }
    } catch (error: any) {
      alert(error);
      console.log(`Erro ao mudar status do pedido`, error);
      setMessage({
        type: 'error',
        message: `Erro ao mudar status do pedido: ${error.message ? error.message : ''}`,
      });
    } finally {
      setLoadingChangeOrder(false);
    }
  };

  const isINextOrderPayloadWaitingPacking = (
    obj: any,
  ): obj is INextOrderPayloadWaitingPacking => {
    console.log(obj);
    return (
      obj &&
      typeof obj.package_size === 'string' &&
      typeof obj.package_base64 === 'string'
    );
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isINextOrderPayloadWaitingPacking(payloadOrderToNext)) return;

    const file = e.target.files?.[0];
    if (!file) return;

    // Verifica o formato
    const validTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/webp',
      'image/heic',
      'image/heif',
    ];

    if (!validTypes.includes(file.type)) {
      addToast({
        id: 'error_image' + new Date().toISOString(),
        type: 'danger',
        text: `Formatos permitidos: PNG, JPG, JPEG, WEBP, HEIC, HEIF`,
        position: 4,
      });
      return;
    }

    try {
      let processedFile = file;

      // Converter HEIC/HEIF para JPEG
      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const convertedBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
        });
        const finalBlob = Array.isArray(convertedBlob)
          ? convertedBlob[0]
          : convertedBlob;
        processedFile = new File([finalBlob], file.name + '.jpeg', {
          type: 'image/jpeg',
        });
      }

      // Compactar a imagem
      const compressedFile = await imageCompression(processedFile, {
        maxSizeMB: 1, // Define o tamanho máximo da imagem em MB
        maxWidthOrHeight: 1920, // Redimensiona para no máximo 1920px de largura ou altura
        useWebWorker: true,
      });

      // Verifica o tamanho após compressão
      if (compressedFile.size > 10 * 1024 * 1024) {
        addToast({
          id: 'error_image' + new Date().toISOString(),
          type: 'danger',
          text: `Tamanho máximo permitido após alterações é de 10MB`,
          position: 4,
        });
        return;
      }

      // Gera base64 e atualiza o estado
      const reader = new FileReader();
      payloadOrderToNext.package_file = compressedFile;

      reader.onloadend = () => {
        const base64String = reader.result as string;
        payloadOrderToNext.package_base64 = base64String;
        setPayloadOrderToNext({ ...payloadOrderToNext });
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Erro ao processar a imagem:', error);
      addToast({
        id: 'error_image' + new Date().toISOString(),
        type: 'danger',
        text: `Erro ao processar a imagem. Tente novamente.`,
        position: 4,
      });
    }
  };

  const getNextStatusLabel = () => {
    switch (orderToNext?.next_action) {
      case 'waiting_packing':
        return `de ${OrderNextActionName.AGUARDANDO_PREPARACAO} para ${OrderNextActionName.AGUARDANDO_RETIRADA}`;
      case 'waiting_shipment':
        return `de ${OrderNextActionName.AGUARDANDO_RETIRADA} para ${OrderNextActionName.EM_TRANSITO}`;
      case 'close':
        return `de ${OrderNextActionName.EM_TRANSITO} para ${OrderNextActionName.CONCLUIDO}`;
      default:
        return ``;
    }
  };

  return (
    <Page>
      <Page.Header
        title={
          changeFinished
            ? `Alteração finalizada`
            : `Alterar status do pedido #${orderToNext?.number || ''}`
        }
        buttonStack={
          <Button appearance="neutral" onClick={() => navigate('/')}>
            <Icon color="currentColor" source={<ArrowLeftIcon />} />{' '}
            {t('General.back')}
          </Button>
        }
      />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Box display="grid" gap="6">
              {message !== null && (
                <>
                  <Alert
                    title="Atenção"
                    appearance={message.type === 'error' ? 'danger' : 'warning'}
                  >
                    {message.message}
                  </Alert>
                  {message.type === 'error' && (
                    <Button appearance="primary" onClick={fetchOrder}>
                      Tentar novamente
                    </Button>
                  )}
                </>
              )}

              {changeFinished && (
                <Button appearance="neutral" onClick={() => navigate('/')}>
                  <Icon color="currentColor" source={<ArrowLeftIcon />} />{' '}
                  {t('General.back')}
                </Button>
              )}

              {orderToNext && !changeFinished && (
                <>
                  <Text>
                    Atenção, ao proceguir com a mudança você estará passando o
                    pedido {getNextStatusLabel()}
                  </Text>
                  {isINextOrderPayloadWaitingPacking(payloadOrderToNext) && (
                    <Box display="flex" gap="6">
                      <Box flex="1 1 110px" width="110px">
                        <FormField label="Foto do pedido">
                          {payloadOrderToNext.package_base64 &&
                          payloadOrderToNext.package_base64 !== '' ? (
                            <Box boxSizing="border-box">
                              <ThumbnailWithAction
                                thumbnail={{
                                  aspectRatio: '1/1',
                                  width: '110px',
                                  alt: 'preview',
                                  src: payloadOrderToNext.package_base64,
                                }}
                              >
                                <IconButton
                                  source={<TrashIcon />}
                                  size="2rem"
                                  onClick={() => {
                                    payloadOrderToNext.package_base64 = '';
                                    delete payloadOrderToNext.package_file;
                                    setPayloadOrderToNext({
                                      ...payloadOrderToNext,
                                    });
                                  }}
                                />
                              </ThumbnailWithAction>
                            </Box>
                          ) : (
                            <FileUploader
                              aspectRatio="none"
                              flexDirection="row"
                              height="110px"
                              accept="image/jpeg,image/gif,image/png,image/webp"
                              placeholder="Enviar "
                              width="110px"
                              onChange={handleImageChange}
                            />
                          )}
                        </FormField>
                      </Box>
                      <Box flex="1 1 100%" width="100%">
                        <FormField label="Tamanho">
                          <Select
                            width="100%"
                            disabled={loadingChangeOrder}
                            name="package_size"
                            id="package_size"
                            value={payloadOrderToNext.package_size}
                            onChange={(e) => {
                              payloadOrderToNext.package_size = e.target.value;
                              setPayloadOrderToNext({ ...payloadOrderToNext });
                            }}
                          >
                            <Select.Option
                              disabled={true}
                              value={``}
                              label="Selecione"
                            />
                            <Select.Option value="P" label="P" />
                            <Select.Option value="M" label="M" />
                            <Select.Option value="G" label="G" />
                            <Select.Option value="XG" label="XG" />
                            <Select.Option value="CARRO" label="CARRO" />
                          </Select>
                        </FormField>
                      </Box>
                    </Box>
                  )}
                  {allowChange && (
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Button
                        appearance="primary"
                        onClick={handleNextAction}
                        disabled={loadingChangeOrder}
                      >
                        {loadingChangeOrder ? <Spinner size="small" /> : <></>}
                        Confirmar Mudança
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default QRCodePage;
