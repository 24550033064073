export interface IMaxLetsTokens {
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
  store_id: number;
  api_key: string;
  api_secret: string;
  token_type: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
}

export const maxLetsEmpty: IMaxLetsTokens = {
  store_id: -1,
  api_key: '',
  api_secret: '',
  token_type: '',
  access_token: '',
  refresh_token: '',
  expires_in: 0,
};
