import React, { useEffect, useState } from 'react';
import {
  Box,
  useToast,
  Icon,
  Text,
  IconButton,
  Button,
  Table,
} from '@nimbus-ds/components';
import nexo from '../../nexoClient';

import { EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import { EditIcon, TrashIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import { useNavigate } from 'react-router-dom';
import { IDistributionCenter } from '../../lib/interfaces/distribution-center.interface';
import { navigateHeaderRemove } from '@tiendanube/nexo';

const DistributionCentersPage: React.FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  const [distributionCenters, setDistributionCenters] = useState<
    IDistributionCenter[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch distribution centers
  const fetchDistributionCenters = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get('/distribution-centers');
      if (result.data) {
        setDistributionCenters(result.data);
      }
    } catch (error) {
      addToast({
        id: `error_fetch_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('DistributionCentersPage.Toast.Fetch.Error')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  // Delete a distribution center
  const handleDelete = async (id: string) => {
    try {
      const result = await apiInstance.delete(`/distribution-centers/${id}`);
      if (result.status === 204) {
        addToast({
          id: `success_delete_${id}`,
          type: 'success',
          text: `${t('DistributionCentersPage.Toast.Delete.Success')}`,
          position: 4,
        });
        setDistributionCenters((prev) =>
          prev.filter((center) => center._id !== id),
        );
      } else {
        throw new Error('Delete failed');
      }
    } catch (error) {
      addToast({
        id: `error_delete_${id}`,
        type: 'danger',
        text: `${t('DistributionCentersPage.Toast.Delete.Error')}`,
        position: 4,
      });
    }
  };

  // Open edit form
  const handleEdit = (id: string) => {
    navigate(`/distribution-centers/form/${id}`);
  };

  useEffect(() => {
    fetchDistributionCenters();
  }, []);

  return (
    <Page>
      <Page.Header
        title={t('DistributionCentersPage.Header.Title')}
        buttonStack={
          <Button
            appearance="primary"
            onClick={() => navigate('/distribution-centers/form')}
          >
            <Icon color="currentColor" source={<PlusCircleIcon />} />
            {t('DistributionCentersPage.Card.NewCenter')}
          </Button>
        }
      />
      <Page.Body display="grid" gap="8">
        <Layout columns="1">
          <Layout.Section>
            <Box display="grid" gap="4">
              {loading || distributionCenters.length > 0 ? (
                <Table>
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell as="th">{t('General.base')}</Table.Cell>
                      <Table.Cell as="th">
                        {t('DistributionCentersPage.Card.ZipBands')}
                      </Table.Cell>
                      <Table.Cell width="100px" as="th">
                        {''}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell as="td">
                          <Text.Skeleton width="180px" />
                        </Table.Cell>
                        <Table.Cell as="td">
                          <Text.Skeleton width="180px" />
                        </Table.Cell>
                        <Table.Cell as="td">
                          <Box display="inline-flex" gap="1">
                            <IconButton.Skeleton /> <IconButton.Skeleton />
                          </Box>
                        </Table.Cell>
                      </Table.Row>
                    ) : (
                      distributionCenters.map((center) => (
                        <Table.Row key={center._id}>
                          <Table.Cell>
                            <Text fontWeight="bold">{center.base}</Text>
                          </Table.Cell>
                          <Table.Cell>
                            <Box display="grid" gap="2">
                              {center.zip_bands.map((band, i) => (
                                <Box display="inline-flex" gap="2" key={i}>
                                  <Text fontWeight="bold">{band.label}:</Text>
                                  <Text>
                                    {`${band.zipcode_start} - ${band.zipcode_end}`}
                                  </Text>
                                </Box>
                              ))}
                            </Box>
                          </Table.Cell>
                          <Table.Cell>
                            <Box
                              display="flex"
                              gap="2"
                              justifyContent="flex-end"
                            >
                              <IconButton
                                size="32px"
                                type="button"
                                title="Editar"
                                onClick={() => handleEdit(center._id!)}
                                source={<EditIcon size="small" />}
                              />
                              <IconButton
                                size="32px"
                                type="button"
                                title="Remover"
                                onClick={() => handleDelete(center._id!)}
                                source={<TrashIcon size="small" />}
                              />
                            </Box>
                          </Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table>
              ) : (
                <Box padding="4">
                  <EmptyMessage
                    title={t('DistributionCentersPage.EmptyMessage.Title')}
                    text={`${t('DistributionCentersPage.EmptyMessage.Text')}`}
                  />
                </Box>
              )}
            </Box>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
};

export default DistributionCentersPage;
