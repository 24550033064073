import React, { useState, useRef } from 'react';
import {
  BoxPackedIcon,
  CloseIcon,
  CogIcon,
  ColorPaletteIcon,
  HomeIcon,
  MoneyIcon,
  QrCodeIcon,
  TruckIcon,
  VerticalStacksIcon,
} from '@nimbus-ds/icons';
import { Html5Qrcode } from 'html5-qrcode';
import { NavTabs } from '@nimbus-ds/patterns';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, IconButton, Tooltip } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';

const NavButtons: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showScanner, setShowScanner] = useState(false);
  const scannerRef = useRef<HTMLDivElement | null>(null);
  const html5QrCodeRef = useRef<Html5Qrcode | null>(null);

  const startScanner = async () => {
    try {
      const devices = await Html5Qrcode.getCameras();
      if (devices && devices.length) {
        const rearCamera = devices.find((device) =>
          device.label.toLowerCase().includes('back'),
        );
        // const cameraId = rearCamera?.id || devices[0].id;

        if (!html5QrCodeRef.current) {
          html5QrCodeRef.current = new Html5Qrcode(scannerRef.current!.id);
        }

        await html5QrCodeRef.current.start(
          { facingMode: 'environment' },
          {
            fps: 10, // Frames por segundo
            qrbox: { width: 250, height: 250 }, // Caixa de leitura
          },
          (decodedText) => {
            console.log(`Código QR Escaneado: ${decodedText}`);
            setShowScanner(false);
            html5QrCodeRef.current?.stop();
            navigate(decodedText.split(`#`)[1] || '/');
          },
          (errorMessage) => {
            console.warn('Erro ao escanear QR Code:', errorMessage);
          },
        );
      } else {
        console.warn('Nenhuma câmera disponível.');
      }
    } catch (error) {
      console.error('Erro ao iniciar o scanner:', error);
    }
  };

  const stopScanner = async () => {
    if (html5QrCodeRef.current) {
      try {
        await html5QrCodeRef.current.stop();
        html5QrCodeRef.current.clear();
      } catch (error) {
        console.error('Erro ao parar o scanner:', error);
      }
    }
    setShowScanner(false);
  };

  return (
    <>
      <NavTabs>
        <NavTabs.Item
          active={pathname === '/'}
          ariaLabel={`${t('Components.NavButtons.Home.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.Home.Helper')}`}
              position="top"
            >
              <HomeIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/');
          }}
        />
        <NavTabs.Item
          active={pathname === '/shelves'}
          ariaLabel={`${t('Components.NavButtons.Shelves.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.Shelves.Helper')}`}
              position="top"
            >
              <BoxPackedIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/shelves');
          }}
        />
        <NavTabs.Item
          active={pathname.startsWith('/shipping-config')}
          ariaLabel={`${t('Components.NavButtons.ShippingConfig.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.ShippingConfig.Helper')}`}
              position="top"
            >
              <TruckIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/shipping-config');
          }}
        />
        <NavTabs.Item
          active={pathname.startsWith('/distribution-centers')}
          ariaLabel={`${t('Components.NavButtons.DistributionCenters.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.DistributionCenters.Helper')}`}
              position="top"
            >
              <VerticalStacksIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/distribution-centers');
          }}
        />
        <NavTabs.Item
          active={pathname === '/message-card'}
          ariaLabel={`${t('Components.NavButtons.CardMessage.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.CardMessage.Helper')}`}
              position="top"
            >
              <ColorPaletteIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/message-card');
          }}
        />
        <NavTabs.Item
          active={pathname === '/store-config'}
          ariaLabel={`${t('Components.NavButtons.StoreConfig.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.StoreConfig.Helper')}`}
              position="top"
            >
              <CogIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/store-config');
          }}
        />
        <NavTabs.Item
          active={pathname === '/billing'}
          ariaLabel={`${t('Components.NavButtons.Billing.Label')}`}
          icon={
            <Tooltip
              content={`${t('Components.NavButtons.Billing.Helper')}`}
              position="top"
            >
              <MoneyIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            navigate('/billing');
          }}
        />
      </NavTabs>
      <Box position="fixed" bottom="20%" right="40px" zIndex="800">
        <IconButton
          source={
            <Tooltip
              content={`${t('Components.NavButtons.QRCodeScan.Label')}`}
              position="top"
            >
              <QrCodeIcon size="medium" />
            </Tooltip>
          }
          onClick={() => {
            setShowScanner(true);
            startScanner(); // Inicia o scanner ao abrir
          }}
        />
      </Box>
      {showScanner && (
        <Box
          position="fixed"
          top="0"
          left="0"
          zIndex="900"
          backgroundColor="neutral-background"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          height="100vh"
        >
          <Box
            id="scanner"
            ref={scannerRef}
            width="300px"
            height="300px"
            borderColor="primary-interactive"
            borderStyle="solid"
            borderWidth="3"
            borderRadius="4"
            overflow="hidden"
          />
          <Box position="absolute" top="5px" right="5px">
            <IconButton onClick={stopScanner} source={<CloseIcon />} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default NavButtons;
