import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Input,
  Sidebar,
  Spinner,
  Text,
  useToast,
} from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import {
  IMaxLetsTokens,
  maxLetsEmpty,
} from '../../lib/interfaces/integrations.interface';

interface Props {
  open: boolean;
  onClose: () => void;
}

const INTEGRATION_API_BASE_URL = `/max-lets-integration`;

const MaxLetsForm: React.FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<IMaxLetsTokens>({
    ...maxLetsEmpty,
  });

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(INTEGRATION_API_BASE_URL);
      if (result.data !== null) {
        const resData = result.data;
        setFormData({ ...maxLetsEmpty, ...resData });
      }
    } catch (error) {
      setFormData({ ...maxLetsEmpty });
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('Components.IntegrationsConfigs.Toasts.Error.GetConfig')}`,
        position: 4,
      });
    }
    setLoading(false);
  };

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    setFormData((prevState) => {
      // Verifica se o campo é aninhado
      const keys = name.split('.');
      const updatedState: any = { ...prevState }; // Alterado para const

      if (keys.length > 1) {
        let nestedState = updatedState;
        for (let i = 0; i < keys.length - 1; i++) {
          if (!nestedState[keys[i]]) nestedState[keys[i]] = {};
          nestedState = nestedState[keys[i]];
        }
        nestedState[keys[keys.length - 1]] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      } else {
        updatedState[name] =
          type === 'checkbox' && 'checked' in event.target
            ? event.target.checked
            : value;
      }

      return updatedState;
    });
  };

  const handleSubmit = async () => {
    if (!formData) return;

    try {
      setLoading(true);
      const payload: IMaxLetsTokens = { ...formData };

      // Remova os campos que não precisam ser enviados
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;

      const result = await apiInstance.post(INTEGRATION_API_BASE_URL, {
        api_key: payload.api_key,
        api_secret: payload.api_secret,
      });

      if (result.status === 200 && result.data.status) {
        addToast({
          id: `submit_${new Date().toISOString()}`,
          type: 'success',
          text: `${t('Components.IntegrationsConfigs.Toasts.Success.Save')}`,
          position: 4,
        });
        onClose();
      } else {
        throw new Error('Erro ao salvar configurações');
      }
    } catch (error) {
      addToast({
        id: `submit_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t('Components.IntegrationsConfigs.Toasts.Error.Save')}`,
        position: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Sidebar maxWidth="500px" padding="base" open={open} onRemove={onClose}>
      <Sidebar.Header
        title={`${t('Components.IntegrationsConfigs.MaxLetsForm.Title')}`}
      />
      <Sidebar.Body>
        <Box
          boxSizing="border-box"
          display="flex"
          flexDirection="column"
          height="100%"
          gap="4"
        >
          <Text>{`${t('Components.IntegrationsConfigs.MaxLetsForm.Message')}`}</Text>
          <FormField
            label={`${t('Components.IntegrationsConfigs.MaxLetsForm.Form.ApiKey.Label')}`}
          >
            <Input
              type="text"
              name="api_key"
              placeholder={`Ex.: e11875c1-d96b-49dc-9d4f-c2df5645739c`}
              value={formData.api_key}
              onChange={handleChangeForm}
              disabled={loading}
            />
          </FormField>
          <FormField
            label={`${t('Components.IntegrationsConfigs.MaxLetsForm.Form.ApiSecret.Label')}`}
          >
            <Input
              type="text"
              name="api_secret"
              placeholder={`Ex.: 14b8e6a802f85ecc2626693b439910c9a36e0c7c97696fa1eb0e0f39425141c`}
              value={formData.api_secret}
              onChange={handleChangeForm}
              disabled={loading}
            />
          </FormField>
        </Box>
      </Sidebar.Body>
      <Sidebar.Footer>
        <Box
          alignItems="center"
          boxSizing="border-box"
          display="flex"
          height="100%"
          justifyContent="space-between"
          padding="2"
          width="100%"
        >
          <Button
            onClick={handleSubmit}
            appearance="primary"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner color="currentColor" size="small" />
                {t(`General.saving`)}
              </>
            ) : (
              t(`General.save`)
            )}
          </Button>
        </Box>
      </Sidebar.Footer>
    </Sidebar>
  );
};
export default MaxLetsForm;
