import { ChangeEvent, useEffect, useState } from 'react';

import {
  Box,
  Link,
  Card,
  useToast,
  Icon,
  Text,
  IconButton,
  Alert,
} from '@nimbus-ds/components';
import { DataList, EmptyMessage, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import {
  EditIcon,
  ForbiddenIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import {
  EmptyShippingConfigCarrier,
  EmptyShippingConfigPeriods,
  EmptyShippingConfigZipBand,
  IShippingConfigCarrier,
  IShippingConfigPeriod,
  IShippingConfigPeriodPrice,
  IShippingConfigZipBand,
} from '../../lib/interfaces/shipping-config.interface';
import {
  ConfigCarrierForm,
  PeriodForm,
  ZipBandForm,
} from '../../components/ShippingConfigForms';
import { useNavigate } from 'react-router-dom';

function ShippingConfigPage() {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [haveShippingCarrier, setHaveShippingCarrier] =
    useState<boolean>(false);
  const [openSidebarCarrier, setOpenSidebarCarrier] = useState<boolean>(false);
  const [openSidebarZipBand, setOpenSidebarZipBand] = useState<boolean>(false);
  const [openSidebarPeriod, setOpenSidebarPeriod] = useState<boolean>(false);
  const [loadingShippingCarrier, setLoadingShippingCarrier] =
    useState<boolean>(true);
  const [loadingShippingConfig, setLoadingShippingConfig] =
    useState<boolean>(true);
  const [shippingCarrier, setShippingCarrier] =
    useState<IShippingConfigCarrier>({ ...EmptyShippingConfigCarrier });
  const [shippingConfigZipBand, setShippingConfigZipBand] = useState<
    (IShippingConfigZipBand & {
      periods: (IShippingConfigPeriod & {
        period_prices: IShippingConfigPeriodPrice[];
      })[];
    })[]
  >([]);
  const [shippingConfigPeriods, setShippingConfigPeriods] = useState<
    IShippingConfigPeriod[]
  >([]);

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [formShippingCarrier, setFormShippingCarrier] =
    useState<IShippingConfigCarrier>({ ...EmptyShippingConfigCarrier });
  const [formShippingZipBand, setFormShippingZipBand] =
    useState<IShippingConfigZipBand>({ ...EmptyShippingConfigZipBand });
  const [formShippingPeriods, setFormShippingPeriods] =
    useState<IShippingConfigPeriod | null>(null);

  const getShippingCarrier = async () => {
    setLoadingShippingCarrier(true);
    try {
      const result = await apiInstance.get(`/shipping-config/carrier`);
      if (result.data && result.status === 200) {
        const haveCarrier =
          result.data &&
          typeof result.data === 'object' &&
          result.data.week_external_id &&
          result.data.week_ns_object &&
          typeof result.data.week_ns_object === 'object'
            ? true
            : false;
        if (haveCarrier) {
          setHaveShippingCarrier(haveCarrier);
          setShippingCarrier(result.data);
        } else {
          addToast({
            id: `error_${new Date().toISOString()}`,
            type: 'primary',
            text: `${result.data.message}`,
            position: 4,
          });
        }
      } else {
        addToast({
          id: `error_${new Date().toISOString()}`,
          type: 'danger',
          text: `${t(
            `ShippingConfigPage.Toast.GetShippingCarrier.ErrorMessage`,
          )}`,
          position: 4,
        });
      }
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t(
          `ShippingConfigPage.Toast.GetShippingCarrier.ErrorMessage`,
        )}`,
        position: 4,
      });
    } finally {
      setLoadingShippingCarrier(false);
    }
  };

  const getShippingConfig = async () => {
    setLoadingShippingConfig(true);
    try {
      const result = await apiInstance.get(`/shipping-config`);
      if (result.data) {
        setShippingConfigZipBand(result.data.zipBands || []);
        setShippingConfigPeriods(result.data.periods || []);
      }
    } catch (error) {
      addToast({
        id: `error_${new Date().toISOString()}`,
        type: 'danger',
        text: `${t(`ShippingConfigPage.Toast.GetShippingConfig.ErrorMessage`)}`,
        position: 4,
      });
    } finally {
      setLoadingShippingConfig(false);
    }
  };

  const refreshData = async () => {
    await getShippingConfig();
    setOpenSidebarZipBand(false);
    setOpenSidebarPeriod(false);
    setOpenSidebarCarrier(false);
    setFormShippingZipBand({ ...EmptyShippingConfigZipBand });
    setFormShippingPeriods({ ...EmptyShippingConfigPeriods });
  };

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    formType: 'zip-band' | 'periods' | 'carrier',
  ) => {
    const { name, value, type } = event.target;

    // Determinar qual formulário está sendo atualizado
    const updatedForm: { [key: string]: any } =
      formType === 'zip-band'
        ? { ...formShippingZipBand }
        : formType === 'periods'
          ? { ...formShippingPeriods }
          : { ...formShippingCarrier };

    console.log(name, value);
    // Atualizar valores aninhados (ex: cut_off_week.time)
    if (name.includes('.')) {
      const keys = name.split('.'); // Dividir o campo por '.'
      const lastKey = keys.pop(); // Última chave
      let target = updatedForm;

      // Percorrer as chaves intermediárias para encontrar o objeto alvo
      for (const key of keys) {
        if (!target[key]) target[key] = {}; // Garantir que o objeto exista
        target = target[key];
      }

      // Atualizar o valor no nível correto
      if (lastKey) {
        target[lastKey] =
          type === 'checkbox' && event.target instanceof HTMLInputElement
            ? (event.target as HTMLInputElement).checked
            : value;
      }
    } else {
      // Atualizar campos simples
      updatedForm[name] =
        type === 'checkbox' && event.target instanceof HTMLInputElement
          ? (event.target as HTMLInputElement).checked
          : value;
    }

    // Atualizar o estado correto com o formulário atualizado
    if (formType === 'zip-band') {
      setFormShippingZipBand(updatedForm as IShippingConfigZipBand);
    } else if (formType === 'periods') {
      setFormShippingPeriods(updatedForm as IShippingConfigPeriod);
    } else if (formType === 'carrier') {
      setFormShippingCarrier(updatedForm as IShippingConfigCarrier);
    }
  };

  const validateForm = (
    formType: 'zip-band' | 'periods' | 'carrier',
  ): boolean => {
    if (formType === 'zip-band') {
      const isValid =
        formShippingZipBand.zip_start !== '' &&
        formShippingZipBand.zip_end !== '' &&
        formShippingZipBand.city !== '' &&
        formShippingZipBand.state !== '';

      if (!isValid) {
        handleToast('Validation', 'danger');
      }
      return isValid;
    } else if (formType === 'periods') {
      const isValid =
        formShippingPeriods?.name !== '' &&
        formShippingPeriods?.time !== '' &&
        formShippingPeriods?.cut_off_week?.time !== '' &&
        formShippingPeriods?.cut_off_saturday?.time !== '' &&
        formShippingPeriods?.cut_off_saturday_interior?.time !== '' &&
        formShippingPeriods?.cut_off_sunday?.time !== '';

      if (!isValid) {
        handleToast('Validation', 'danger');
      }
      return isValid;
    } else if (formType === 'carrier') {
      const isValid = formShippingCarrier?.name !== '';
      if (!isValid) {
        handleToast('Validation', 'danger');
      }
      return isValid;
    }
    return true;
  };

  const handleSubmit = async (
    endpoint: string,
    formType: 'zip-band' | 'periods' | 'carrier',
  ) => {
    if (!validateForm(formType)) {
      handleToast('Register', 'danger');
      return;
    }

    setLoadingForm(true);

    try {
      let result: any = null;
      if (formType === 'carrier') {
        delete formShippingCarrier._id;
        result = await apiInstance.post(`${endpoint}`, {
          ...formShippingCarrier,
        });
      } else {
        const isEdit =
          formType === 'zip-band'
            ? !!formShippingZipBand._id
            : !!formShippingPeriods?._id;

        const payload =
          formType === 'zip-band'
            ? { ...formShippingZipBand }
            : { ...formShippingPeriods };

        const url = isEdit ? `${endpoint}/${payload._id}` : endpoint;

        delete payload._id;
        delete payload.store_id;

        result = isEdit
          ? await apiInstance.put(url, payload)
          : await apiInstance.post(url, payload);
      }

      if (result.status === 200 || result.status === 201) {
        handleToast('Register', 'success');
        await refreshData();
      } else {
        throw new Error('Failed to submit');
      }
    } catch (error) {
      handleToast('Register', 'danger');
    } finally {
      setLoadingForm(false);
    }
  };

  const handleToast = (
    action: 'Delete' | 'Register' | 'Validation',
    type: 'danger' | 'success',
  ) => {
    addToast({
      id: `${type}_${action}_${new Date().toISOString()}`,
      type,
      text: `${t(
        `Components.Toasts.${action}.${
          type === 'danger' ? 'Error' : 'Success'
        }`,
      )}`,
      position: 4,
    });
  };

  const getEndpointByType = (type: 'zip-band' | 'periods' | 'carrier') => {
    if (type === 'zip-band') {
      return '/shipping-config/zip-bands';
    } else if (type === 'periods') {
      return '/shipping-config/periods';
    } else if (type === 'carrier') {
      return '/shipping-config/carrier';
    }
    return '';
  };

  const handleRemoveItem = async (
    _id: string,
    type: 'zip-band' | 'periods',
  ) => {
    setLoadingForm(true);
    try {
      const result = await apiInstance.delete(
        `${getEndpointByType(type)}/${_id}`,
      );

      if (result.status >= 200 && result.status < 300) {
        handleToast('Delete', 'success');

        await refreshData();
      } else {
        throw new Error('Failed to submit');
      }
    } catch (error) {
      handleToast('Delete', 'danger');
    } finally {
      setLoadingForm(false);
    }
  };

  const openEditForm = (
    data:
      | IShippingConfigZipBand
      | IShippingConfigPeriod
      | IShippingConfigCarrier,
    type: 'zip-band' | 'periods' | 'carrier',
  ) => {
    if (type === 'zip-band') {
      setFormShippingZipBand(data as IShippingConfigZipBand);
      setOpenSidebarZipBand(true);
    } else if (type === 'periods') {
      setFormShippingPeriods(data as IShippingConfigPeriod);
      setOpenSidebarPeriod(true);
    } else if (type === 'carrier') {
      setFormShippingCarrier(data as IShippingConfigCarrier);
      setOpenSidebarCarrier(true);
    }
  };

  const toggleOpenSidebar = (type: 'zip-band' | 'periods' | 'carrier') => {
    if (type === 'periods') {
      setOpenSidebarPeriod(!openSidebarPeriod);
    } else if (type === 'zip-band') {
      setOpenSidebarZipBand(!openSidebarZipBand);
    } else if (type === 'carrier') {
      setOpenSidebarCarrier(!openSidebarCarrier);
    }
  };

  useEffect(() => {
    navigateHeaderRemove(nexo);
    getShippingCarrier();
    getShippingConfig();
  }, []);

  return (
    <>
      <Page>
        <Page.Header title={t('ShippingConfigPage.Title')} />
        <Page.Body display="grid" gap="8">
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="4">
                {/* SHIPPINGS */}
                <Card padding="none">
                  <Card.Header
                    padding="base"
                    title={`${t('ShippingConfigPage.CardShippings.Title')}`}
                  />
                  <Card.Body>
                    <Box display="grid" gap="4">
                      <DataList>
                        {loadingShippingCarrier ? (
                          <DataList.Row key={0}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text.Skeleton width="180px" />
                              <IconButton.Skeleton />
                            </Box>
                          </DataList.Row>
                        ) : haveShippingCarrier ? (
                          <DataList.Row key={shippingCarrier?._id} gap="1">
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text fontWeight="bold">
                                {shippingCarrier?.name}
                              </Text>

                              <Box display="flex" gap="2">
                                <IconButton
                                  size="32px"
                                  type="button"
                                  title={`${t('General.edit')}`}
                                  onClick={() =>
                                    openEditForm(shippingCarrier, 'carrier')
                                  }
                                  source={<EditIcon size="small" />}
                                />
                              </Box>
                            </Box>
                          </DataList.Row>
                        ) : (
                          <DataList.Row>
                            <EmptyMessage
                              title={`${t(
                                'ShippingConfigPage.CardShippings.EmptyMessage.Title',
                              )}`}
                              text={`${t(
                                'ShippingConfigPage.CardShippings.EmptyMessage.Text',
                              )}`}
                              icon={<ForbiddenIcon size={32} />}
                              actions={
                                <Link
                                  appearance="primary"
                                  as="button"
                                  onClick={() => toggleOpenSidebar('carrier')}
                                >
                                  <Icon
                                    color="currentColor"
                                    source={<PlusCircleIcon />}
                                  />
                                  {`${t(
                                    'ShippingConfigPage.CardShippings.CtaGoToNewShipping',
                                  )}`}
                                </Link>
                              }
                            />
                          </DataList.Row>
                        )}
                      </DataList>
                    </Box>
                  </Card.Body>
                </Card>
              </Box>
            </Layout.Section>
          </Layout>
          <Layout columns="2 - symmetric">
            <Layout.Section>
              <Box display="grid" gap="4">
                {/* CARD PERIODS */}
                <Card padding="none">
                  <Card.Header
                    padding="base"
                    title={`${t(
                      'ShippingConfigPage.CardShippingPeriods.Title',
                    )}`}
                  />
                  <Card.Body>
                    {haveShippingCarrier ? (
                      <Box display="grid" gap="4">
                        <Box paddingX="4">
                          <Link
                            appearance="primary"
                            as="button"
                            onClick={() => {
                              setFormShippingPeriods({
                                ...EmptyShippingConfigPeriods,
                              });
                              toggleOpenSidebar('periods');
                            }}
                          >
                            <Icon
                              color="currentColor"
                              source={<PlusCircleIcon />}
                            />
                            {`${t(
                              'ShippingConfigPage.CardShippingPeriods.CtaNewPeriod',
                            )}`}
                          </Link>
                        </Box>

                        <DataList>
                          {loadingShippingConfig ? (
                            <DataList.Row key={0}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Text.Skeleton width="180px" />
                                <IconButton.Skeleton />
                              </Box>
                            </DataList.Row>
                          ) : shippingConfigPeriods.length ? (
                            shippingConfigPeriods.map((item, index) => (
                              <DataList.Row key={index} gap="1">
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Box display="grid" gap="2">
                                    <Text fontWeight="bold">
                                      {item.name} ({item.time})
                                    </Text>
                                  </Box>

                                  <Box display="flex" gap="2">
                                    <IconButton
                                      size="32px"
                                      type="button"
                                      title={`${t('General.edit')}`}
                                      onClick={() =>
                                        openEditForm(item, 'periods')
                                      }
                                      source={<EditIcon size="small" />}
                                    />
                                    <IconButton
                                      size="32px"
                                      type="button"
                                      title={`${t('General.remove')}`}
                                      onClick={() => {
                                        if (item._id) {
                                          handleRemoveItem(item._id, 'periods');
                                        }
                                      }}
                                      source={<TrashIcon size="small" />}
                                    />
                                  </Box>
                                </Box>
                              </DataList.Row>
                            ))
                          ) : (
                            <DataList.Row>
                              <EmptyMessage
                                title={`${t(
                                  'ShippingConfigPage.CardShippingPeriods.EmptyMessage.Title',
                                )}`}
                                text={`${t(
                                  'ShippingConfigPage.CardShippingPeriods.EmptyMessage.Text',
                                )}`}
                              />
                            </DataList.Row>
                          )}
                        </DataList>
                      </Box>
                    ) : (
                      <Box padding="4">
                        <Alert
                          title={`${t(
                            'ShippingConfigPage.CardShippingConfigs.AlertNotCarrier.Title',
                          )}`}
                        >
                          {`${t(
                            'ShippingConfigPage.CardShippingConfigs.AlertNotCarrier.Text',
                          )}`}
                        </Alert>
                      </Box>
                    )}
                  </Card.Body>
                </Card>
              </Box>
            </Layout.Section>
            <Layout.Section>
              <Box display="grid" gap="4">
                {/* CARD ZIP BANDS */}
                <Card padding="none">
                  <Card.Header
                    padding="base"
                    title={`${t(
                      'ShippingConfigPage.CardShippingConfigs.Title',
                    )}`}
                  />
                  <Card.Body>
                    {haveShippingCarrier ? (
                      <Box display="grid" gap="4">
                        <Box paddingX="4">
                          <Link
                            appearance="primary"
                            as="button"
                            onClick={() => {
                              setFormShippingZipBand({
                                ...EmptyShippingConfigZipBand,
                              });
                              toggleOpenSidebar('zip-band');
                            }}
                          >
                            <Icon
                              color="currentColor"
                              source={<PlusCircleIcon />}
                            />
                            {`${t(
                              'ShippingConfigPage.CardShippingConfigs.CtaNewZipBand',
                            )}`}
                          </Link>
                        </Box>

                        <DataList>
                          {shippingConfigZipBand.length ? (
                            shippingConfigZipBand.map((zipBand) => (
                              <DataList.Row key={zipBand._id}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap="2"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Text fontWeight="bold">
                                      {zipBand.city}/{zipBand.state} (
                                      {zipBand.zip_start} - {zipBand.zip_end})
                                    </Text>
                                    <Box display="flex" gap="2">
                                      <Box display="flex" gap="2">
                                        <IconButton
                                          size="32px"
                                          type="button"
                                          title={`${t('General.associate_periods')}`}
                                          onClick={() =>
                                            navigate(
                                              `/shipping-config/associate-periods/${zipBand._id}`,
                                            )
                                          }
                                          source={
                                            <PlusCircleIcon size="small" />
                                          }
                                        />
                                        <IconButton
                                          size="32px"
                                          type="button"
                                          title={`${t('General.edit')}`}
                                          onClick={() =>
                                            openEditForm(zipBand, 'zip-band')
                                          }
                                          source={<EditIcon size="small" />}
                                        />
                                        <IconButton
                                          size="32px"
                                          type="button"
                                          title={`${t('General.remove')}`}
                                          onClick={() => {
                                            if (zipBand._id) {
                                              handleRemoveItem(
                                                zipBand._id,
                                                'zip-band',
                                              );
                                            }
                                          }}
                                          source={<TrashIcon size="small" />}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </DataList.Row>
                            ))
                          ) : (
                            <DataList.Row>
                              <EmptyMessage
                                title={`${t(
                                  'ShippingConfigPage.CardShippingConfigs.EmptyMessage.Title',
                                )}`}
                                text={`${t(
                                  'ShippingConfigPage.CardShippingConfigs.EmptyMessage.Text',
                                )}`}
                              />
                            </DataList.Row>
                          )}
                        </DataList>
                      </Box>
                    ) : (
                      <Box padding="4">
                        <Alert
                          title={`${t(
                            'ShippingConfigPage.CardShippingConfigs.AlertNotCarrier.Title',
                          )}`}
                        >
                          {`${t(
                            'ShippingConfigPage.CardShippingConfigs.AlertNotCarrier.Text',
                          )}`}
                        </Alert>
                      </Box>
                    )}
                  </Card.Body>
                </Card>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>

      <ConfigCarrierForm
        open={openSidebarCarrier}
        onClose={() => toggleOpenSidebar('carrier')}
        formState={formShippingCarrier || {}}
        onChange={(e) => handleChangeForm(e, 'carrier')}
        onSubmit={() => handleSubmit(getEndpointByType('carrier'), 'carrier')}
        loading={loadingForm}
      />

      <ZipBandForm
        open={openSidebarZipBand}
        onClose={() => toggleOpenSidebar('zip-band')}
        formState={formShippingZipBand || {}}
        onChange={(e) => handleChangeForm(e, 'zip-band')}
        onSubmit={() => handleSubmit(getEndpointByType('zip-band'), 'zip-band')}
        loading={loadingForm}
      />

      <PeriodForm
        open={openSidebarPeriod}
        onClose={() => toggleOpenSidebar('periods')}
        formState={formShippingPeriods || {}}
        onChange={(e) => handleChangeForm(e, 'periods')}
        onSubmit={() => handleSubmit(getEndpointByType('periods'), 'periods')}
        loading={loadingForm}
      />
    </>
  );
}

export default ShippingConfigPage;
