export interface IPosition {
  position: number; // Posição na linha
  isOccupied: boolean; // Indica se está ocupado
  orderId?: string; // ID do pedido (caso ocupado)
}

export interface ILine {
  lineNumber: number; // Número da linha
  positions: IPosition[]; // Lista de posições
}

export interface IShelf {
  _id?: string;
  name: string; // Número da prateleira
  lines: ILine[]; // Linhas da prateleira
}

export interface IStoreShelves {
  _id?: string;
  store_id: number; // ID da loja
  shelves: IShelf[]; // Lista de prateleiras
  createdAt?: string;
  updatedAt?: string;
}

export interface IShelfForm extends IShelf {
  lineCount?: number; // Quantidade de linhas
  positionsPerLine?: number; // Quantidade de posições por linha
}

export const EmptyStoreShelves: IStoreShelves = {
  store_id: 0,
  shelves: [],
};
