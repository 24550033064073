import { connect, getStoreInfo, iAmReady } from '@tiendanube/nexo';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import nexo from './nexoClient';
import NexoSyncRoute from './NexoSyncRoute';
import {
  AssociatePeriodsPage,
  ConfigsPage,
  MainPage,
  ShelvesPage,
  UnauthorizedPage,
} from './pages';
import { DarkModeProvider, BaseLayout } from './components';

import { ToastProvider } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import BillingPage from './pages/BillingPage';
import ProtectedRoute from './ProtectedRoute';
import ShippingConfigPage from './pages/ShippingConfigPage';
import DistributionCentersPage from './pages/DistributionCenterPage';
import DistributionCentersFormPage from './pages/DistributionCenterFormPage';
import QRCodePage from './pages/QRCodePage';
import MessageCardPage from './pages/MessageCardPage';

function App() {
  const [isConnect, setIsConnect] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    connect(nexo).then(async () => {
      setIsConnect(true);
      iAmReady(nexo);

      const store = await getStoreInfo(nexo);
      i18n.changeLanguage(store.language);
    });
  }, []);

  if (!isConnect) return <div>connecting..</div>;

  return (
    <DarkModeProvider>
      <BrowserRouter>
        <ToastProvider>
          <BaseLayout>
            <NexoSyncRoute>
              <Routes>
                <Route
                  path="/"
                  element={<ProtectedRoute element={<MainPage />} />}
                />
                <Route
                  path="/message-card/:order_id?"
                  element={<ProtectedRoute element={<MessageCardPage />} />}
                />
                <Route
                  path="/shipping-config/associate-periods/:zipBandId"
                  element={
                    <ProtectedRoute element={<AssociatePeriodsPage />} />
                  }
                />
                <Route
                  path="/distribution-centers/form/:id?"
                  element={
                    <ProtectedRoute element={<DistributionCentersFormPage />} />
                  }
                />
                <Route
                  path="/qr-code/order/:order_id/next-action/:next_action"
                  element={<ProtectedRoute element={<QRCodePage />} />}
                />
                <Route
                  path="/distribution-centers"
                  element={
                    <ProtectedRoute element={<DistributionCentersPage />} />
                  }
                />
                <Route
                  path="/shipping-config"
                  element={<ProtectedRoute element={<ShippingConfigPage />} />}
                />
                <Route
                  path="/shelves"
                  element={<ProtectedRoute element={<ShelvesPage />} />}
                />
                <Route
                  path="/store-config"
                  element={<ProtectedRoute element={<ConfigsPage />} />}
                />
                <Route path="/billing" element={<BillingPage />} />
                <Route path="/unauthorized" element={<UnauthorizedPage />} />
              </Routes>
            </NexoSyncRoute>
          </BaseLayout>
        </ToastProvider>
      </BrowserRouter>
    </DarkModeProvider>
  );
}

export default App;
