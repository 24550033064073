import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  Sidebar,
  Spinner,
  Checkbox,
  Toggle,
} from '@nimbus-ds/components';
import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';

interface ZipBandFormProps {
  open: boolean;
  onClose: () => void;
  formState: {
    active?: boolean;
    zip_start?: string;
    zip_end?: string;
    city?: string;
    state?: string;
    is_interior?: boolean;
  };
  onChange: (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  onSubmit: (isValid: boolean) => void;
  loading: boolean;
}

const ZipBandForm: React.FC<ZipBandFormProps> = ({
  open,
  onClose,
  formState,
  onChange,
  onSubmit,
  loading,
}) => {
  const [errors, setErrors] = useState<{
    zip_start?: string;
    zip_end?: string;
    city?: string;
    state?: string;
  }>({});

  const { t } = useTranslation();

  const validateFields = () => {
    const newErrors: typeof errors = {};

    if (!formState.zip_start) {
      newErrors.zip_start = 'CEP Inicial é obrigatório';
    }
    if (!formState.zip_end) {
      newErrors.zip_end = 'CEP Final é obrigatório';
    }
    if (!formState.city) {
      newErrors.city = 'Cidade é obrigatória';
    }
    if (!formState.state) {
      newErrors.state = 'Estado é obrigatório';
    }

    setErrors(newErrors);

    // Retorna true se não houver erros
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = validateFields();
    if (isValid) {
      onSubmit(true);
    }
  };

  return (
    <Sidebar maxWidth="500px" padding="base" open={open} onRemove={onClose}>
      <Sidebar.Header title={`${t('Components.ZipBandForm.Title')}`} />
      <Box display="grid" gap="4">
        <Checkbox
          name="active"
          checked={formState.active || false}
          onChange={onChange}
          disabled={loading}
          label={`${t('General.active')}`}
        />
        <FormField label={`${t('Components.ZipBandForm.Form.ZipStart.Label')}`}>
          <Input
            appearance={errors.zip_start ? 'danger' : 'neutral'}
            required={true}
            type="text"
            name="zip_start"
            value={formState.zip_start || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField label={`${t('Components.ZipBandForm.Form.ZipEnd.Label')}`}>
          <Input
            appearance={errors.zip_end ? 'danger' : 'neutral'}
            required={true}
            type="text"
            name="zip_end"
            value={formState.zip_end || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField label={`${t('Components.ZipBandForm.Form.City.Label')}`}>
          <Input
            appearance={errors.city ? 'danger' : 'neutral'}
            required={true}
            type="text"
            name="city"
            value={formState.city || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField label={`${t('Components.ZipBandForm.Form.State.Label')}`}>
          <Input
            appearance={errors.state ? 'danger' : 'neutral'}
            required={true}
            type="text"
            name="state"
            value={formState.state || ''}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <FormField
          label={`${t('Components.ZipBandForm.Form.IsInterior.Label')}`}
        >
          <Toggle
            name="is_interior"
            label="Interior"
            checked={formState.is_interior || false}
            onChange={onChange}
            disabled={loading}
          />
        </FormField>
        <Button onClick={handleSubmit} appearance="primary" disabled={loading}>
          {loading ? (
            <Spinner color="currentColor" size="small" />
          ) : (
            `${t('General.save')}`
          )}
        </Button>
      </Box>
    </Sidebar>
  );
};

export default ZipBandForm;
